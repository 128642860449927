import React from "react";
import DashboardProfileStatsCard from "./dashboardProfileStatsCard/dashboardProfileStatsCard";
import { PostUtil } from "../new-post-new/post-util";

const DashboardProfileStats = ({ service, serviceName }) => {
  switch (serviceName) {
    case PostUtil.DashboardProfileServiceName.FACEBOOK:
      return <DashboardProfileStatsCard service={service} name={"Followers"} count={service.followers_count} profilePicture={service.url} serviceName={'FACEBOOK'}/>;
    case PostUtil.DashboardProfileServiceName.INSTAGRAM:
      return <DashboardProfileStatsCard service={service} name={"Followers"} count={service.followers_count} profilePicture={service.profile_picture_url} serviceName={'INSTAGRAM'}/>;
    case PostUtil.DashboardProfileServiceName.LINKEDIN :
      return <DashboardProfileStatsCard service={service} name={"Followers"} count={service.totalPageFollowers} profilePicture={service.url} serviceName={'LINKEDIN'}/>;
    case PostUtil.DashboardProfileServiceName.YOUTUBE:
      return <DashboardProfileStatsCard service={service} name={"Subscribers"} count={service.subscriberCount} profilePicture={service.picture} serviceName={'YOUTUBE'}/>;
    case PostUtil.DashboardProfileServiceName.GBUSINESS:
      return <DashboardProfileStatsCard service={service} name ={"Review Count"} count={service.totalReviewCount} profilePicture={service.url} serviceName={'GBUSINESS'}/>;
    case PostUtil.DashboardProfileServiceName.PINTEREST:
      return <DashboardProfileStatsCard service={service} name ={"Followers"} count={service.follower_count} profilePicture={service.url} serviceName={'PINTEREST'}/>;
    case PostUtil.DashboardProfileServiceName.TIKTOK:
      return <DashboardProfileStatsCard service={service} name ={"Followers"} count={service.follower_count} profilePicture={service.url} serviceName={'TIKTOK'}/>;
    case PostUtil.DashboardProfileServiceName.TWITTER:
      return <DashboardProfileStatsCard service={service} name ={"Followers"} count={service.follower_count} profilePicture={service.url} serviceName={'TWITTER'}/>;
    default:
      return null;
  }
};

export default DashboardProfileStats;
