import { useState } from "react";
import { useEffect,useRef } from "react";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import TwitterAuthorizationModal from "./twitterAuthorizationModal";
import { useToaster } from "src/utils/toaster/toasterContext";
import { Config } from "src/config";
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';

// var openedWindow;
const TwitterIntegration = (props) => {
  const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
  const { showToast } = useToaster();
  const isCustomDomain = CommonHelper.CustomDomain();
  let isProcessing = false;
  let intervalId = null;
  const messageListenerRef = useRef(null);

  useEffect(() => {
    intervalId = setInterval(() => {
      checkAccessTokenReceivedFromTwitter();
    }, Config.Common.socialAccountsIntegrationInterval);
    return (() => {
      clearInterval(intervalId);
      cleanupListener();
    })
  }, [])
  const twitterSuccessCallback = (data, message) => {
    if (data && data.oauth_token) {
      window.open(
        `https://api.twitter.com/oauth/authenticate?oauth_token=${data.oauth_token}`,
        "_blank"
      );
    }
  }
  const cleanupListener = () => {
    if (messageListenerRef.current) {
        window.removeEventListener("message", messageListenerRef.current, false);
        messageListenerRef.current = null; // Reset the ref
    }
};
  const twitterFailureCallback = (message) => {
    showToast(message, 'e');
    handleClose();
  }
  const getTwitterOauthToken = () => {
    let getProps = {
      url: integrateModuleURL.twitterIntegration,
      successCallback: twitterSuccessCallback,
      failureCallback: twitterFailureCallback
    }
    HttpServices.Get(getProps);
  };

  const checkAccessTokenReceivedFromTwitter = () => {
    if (isCustomDomain) {
      const handleMessage = (event) => {
          const { type, twitter_token ,twitter_token_secret} = event.data;
          if (type === "twitter_token" && twitter_token && !isProcessing) {
              isProcessing=true;
              cleanupListener(); // Remove event listener
              clearInterval(intervalId); // Stop polling
              sendAccessTokenToServer(twitter_token,twitter_token_secret); // Process the code
          }
      };

      if (!messageListenerRef.current) {
        window.addEventListener("message", handleMessage, false);
        messageListenerRef.current = handleMessage; // Save the reference
    }
  } else {
    var token = localStorage.getItem("twitter_token");
    var tokenSecret = localStorage.getItem("twitter_token_secret");
    var error = localStorage.getItem("twitter_error");
    if (token && tokenSecret) {
      localStorage.removeItem("twitter_token");
      localStorage.removeItem("twitter_token_secret");
      clearInterval(intervalId);
      sendAccessTokenToServer(token, tokenSecret);
    }
    else if (error) {
      localStorage.removeItem("twitter_error");
      clearInterval(intervalId);
      showToast(error, 'e');
    }
  }
  };
  const twitterIntegrationSuccessCallback = (data, message) => {
    showToast(message, 's');
    handleClose();
  }
  const twitterIntegrationFailureCallback = (message) => {
    showToast(message, 'e');
    handleClose();
  }
  const sendAccessTokenToServer = (token, tokenSecret) => {
    let twitterForm = {
      token,
      token_secret: tokenSecret,
    };
    let postProps = {
      url: integrateModuleURL.sendTwitterAccessToken,
      body: twitterForm,
      successCallback: twitterIntegrationSuccessCallback,
      failureCallback: twitterIntegrationFailureCallback
    }
    HttpServices.Post(postProps);
  };

  const handleClose = () => {
    props.handleClose();
    cleanupListener();
  }

  return (
    <>
    {isAuthorizationModalOpen &&
    <TwitterAuthorizationModal
      handleConnectTwitter={getTwitterOauthToken}
      handleClose={handleClose}
    />}
     {isCustomDomain && <CustomDomainRedirectIframe serviceName="twitter"/> }
     </>
  )
};
export default TwitterIntegration;
