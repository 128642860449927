import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Link, Stack, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import OutComponent from 'src/out-component';
import InitialSetup from 'src/pages/common/initial-setup.component';
import GoogleSignInComponent from 'src/pages/google-signin/googleSignIn.component';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { LocalStorage } from 'src/utils/storage/local-storage';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ValidationTool from 'src/utils/validationHelper';

const LoginForm = ({ isBrand2SocialDomain }) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formSchema, setFormSchema] = useState({});
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);
  const [isInitFailed, setInitFailed] = useState(false);

  const { showToast } = useToaster();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let token = LocalStorage.getItem('token');
    if (token) {
      navigate(CommonHelper.DefaultPermissionRoute);
    } else {
      generateFormModelSchema();
    }
  }, []);

  useEffect(() => {}, [email, password, showPassword, formSchema, formValidationErrors]);

  const generateFormModelSchema = () => {
    let fields = [
      ValidationTool.GenerateSchema('email', 'Email', 'EMAIL', true),
      ValidationTool.GenerateSchema('password', 'Password', 'TEXT', true),
    ];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    let loginForm = {
      email: email,
      password: password,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, loginForm);
    if (isValidForm) handleLogin();
    else setFormValidationErrors(formValidationErrors);
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'email':
        setEmail(value ? value.toLowerCase().trim() : value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleOnBlur = (event) => {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    let validationErrors = { ...formValidationErrors };
    let message = ValidationTool.ValidateInput(formSchema, fieldValue, fieldName);
    if (message) validationErrors[fieldName] = message;
    else validationErrors[fieldName] = '';
    setFormValidationErrors(validationErrors);
  };

  const handleLogin = (isDemoLogin = false) => {
    setLoading(!isDemoLogin);
    let loginForm = {
      email: email,
      password: password,
      device_type: isMobile ? CommonHelper.DeviceType.Mobile : CommonHelper.DeviceType.Desktop,
    };
    if (isDemoLogin)
      loginForm = {
        email: 'demo@brand2social.com',
        password: 'demo1234',
        device_type: isMobile ? CommonHelper.DeviceType.Mobile : CommonHelper.DeviceType.Desktop,
      };
    let postProps = {
      url: userModuleURL.login,
      body: loginForm,
      successCallback: handleLoginSuccess,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const initSuccessCallback = (data) => {
    let isSetupDone = InitialSetup(data);
    if (isSetupDone) {
      LocalStorage.setItem('isRouteLoaded', JSON.stringify(true));
      navigate(CommonHelper.DefaultPermissionRoute);
    } else {
      setInitFailed(true);
    }
  };

  const initCallFailureCallback = () => {
    setInitFailed(true);
  };

  const handleLoginSuccess = (data, message) => {
    CommonHelper.AppLoginCall(data);
  };

  const failureCallback = (message) => {
    showToast(message, 'e');
    setLoading(false);
  };

  const applyDemoCredential = () => {
    setDemoLoading(true);
    handleLogin(true);
  };

  return (
    <>
      {isInitFailed ? (
        <OutComponent />
      ) : (
        <>
          <form onSubmit={handleLoginClick}>
            <Stack spacing={3}>
              <TextField
                name="email"
                label="Email address"
                placeholder="Enter email address"
                onChange={handleInputChange}
                value={email}
                color={email === '' ? 'error' : 'success'}
                helperText={formValidationErrors?.email}
                onBlur={handleOnBlur}
                InputLabelProps={{ shrink: true }}
                error={formValidationErrors?.email}
              />
              <TextField
                name="password"
                label="Password"
                placeholder="Enter your password"
                type={showPassword ? 'text' : 'password'}
                onChange={handleInputChange}
                onBlur={handleOnBlur}
                value={password}
                color={password === '' ? 'error' : 'success'}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={formValidationErrors?.password}
                error={formValidationErrors?.password}
              />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
              {/* <Checkbox name="remember" label="Remember me" /> */}
              <Link
                variant="subtitle2"
                underline="hover"
                style={{ cursor: 'pointer' }}
                component={RouterLink}
                to={'/reset-password'}
              >
                Forgot password?
              </Link>
            </Stack>
            <LoadingButton fullWidth size="large" type="submit" loading={loading} variant="contained">
              Login
            </LoadingButton>
          </form>
          {isBrand2SocialDomain && (
            <>
              <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <LoadingButton
                  fullWidth
                  size="large"
                  loading={demoLoading}
                  variant="contained"
                  onClick={applyDemoCredential}
                >
                  Use Demo Account
                </LoadingButton>
              </Stack>
              <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <GoogleSignInComponent
                  handleLoginSuccess={handleLoginSuccess}
                  googleButtonText="Signed in with Google"
                />
              </Stack>
            </>
          )}
        </>
      )}
    </>
  );
};
export default LoginForm;
