import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import LoadFacebookSdk from '../loadFacebookSdk';
import InstagramAuthorizationModal from '../instagramIntegration/instagramAuthorizationModal';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';
import { Config } from 'src/config';

const InstagramReAuthenticate = ({ AccountId, handleClose,getConnectedNetworks }) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const [isLoadSdk, setLoadSdk] = useState(false);
    const { showToast } = useToaster();
    let isProcessing = false;
    const isCustomDomain = CommonHelper.CustomDomain();
    let intervalId = null;

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthoCodeReceivedFromFacebook();
        }, Config.Common.socialAccountsIntegrationInterval);
        return () => {
            clearInterval(intervalId);
        };
    }, [])
    const instagramAccountSuccessCallback = (data, message) => {
        showToast(message, 's');
        getConnectedNetworks();
        handleClose();
    }
    const instagramAccountFailureCallback = (message) => {
        showToast(message, 'e');
        handleClose();
    }
    const getInstagramAccounts = (code) => {
        let instagramForm = {
            code,
            account_id:AccountId
        };
        let postProps = {
            url: integrateModuleURL.reAuthenticateInstagramAccounts,
            body: instagramForm,
            successCallback: instagramAccountSuccessCallback,
            failureCallback: instagramAccountFailureCallback
        }
        HttpServices.Post(postProps);
    };
    const checkAuthoCodeReceivedFromFacebook = () => {
        if (isCustomDomain) {
            const handleMessage = (event) => {
                const { type, code } = event.data;
                if (type === "fb_auth_code" && code && !isProcessing) {
                    isProcessing = true
                    window.removeEventListener("message", handleMessage, false); // Remove event listener
                    clearInterval(intervalId); // Stop polling
                    getInstagramAccounts(code); // Process the code
                }
            };
    
            window.addEventListener("message", handleMessage, false);
        } else {
        var code = localStorage.getItem("fb_autho_code");
        var error = localStorage.getItem("fb_error");
        if (code) {
            localStorage.removeItem("fb_autho_code");
            clearInterval(intervalId);
            getInstagramAccounts(code);
        }
        else if (error) {
            localStorage.removeItem("fb_error");
            clearInterval(intervalId);
            showToast(error, 'e');
        }
    }
    }

    const handleConnectInstagram = () => {
        setLoadSdk(true);
    }

  
    return (
        <>
            {isAuthorizationModalOpen &&
                <InstagramAuthorizationModal
                    handleConnectInstagram={handleConnectInstagram}
                    handleClose={handleClose}
                />}
            {isLoadSdk && <LoadFacebookSdk />}
            {isCustomDomain && <CustomDomainRedirectIframe serviceName="instagram"/> }
        </>
    );
};
export default InstagramReAuthenticate;
