import { useState } from 'react';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import UploadCsvFile from './uploadCsvFile';

const CsvFileUploadComponent = ({ handleBulkJson }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const maxFileSizeInBytes = 10485760;
    const { showToast } = useToaster();
    const handleCsv = (files) => {
        const supportedFiles = files.filter((file) => file.type === 'text/csv');

        if (supportedFiles.some((file) => file.size > maxFileSizeInBytes)) {
            showToast('Maximum file size for CSV is 10 MB. Please select a smaller file.', 'e');
            return;
        }
    };

    return (
        <>
            <UploadCsvFile
                name="csv"
                accept={['.csv']}
                onDrop={handleCsv}
                handleBulkJson={handleBulkJson}
                isMobile={isMobile}
            />
        </>
    );
};

export default CsvFileUploadComponent;