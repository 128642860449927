import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useTheme,
    Typography
} from '@mui/material';
import dayjs from "dayjs";
import { PostUtil } from './post-util';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import TextIconLabel from 'src/components/TextIconLabel';

const CsvFileErrorPopup = ({ onClose, jsonData, errors }) => {
    const theme = useTheme();
    const mapJsonToCsvRows = (data) => {
        const rows = [];
        data.forEach((schedule) => {
            schedule.posts.forEach((post, index) => {
                const mediaUrls = post.media_url.split(",") || [];
                const ExistingUrls = post.existing_url.split(",") || [];
                const SocialMedia = post.connected_profile_details?.social_media.toLowerCase();
                const PostType = post.post_type.toLowerCase();
                const LocalScheduleDate = schedule.schedule_date ? dayjs(schedule.schedule_date).local().format("DD/MM/YYYY HH:mm:ss") : "";
                const LocalStartDate = post.start_date ? dayjs(post.start_date).local().format("DD/MM/YYYY HH:mm:ss") : "";
                const LocalEndDate = post.end_date ? dayjs(post.end_date).local().format("DD/MM/YYYY HH:mm:ss") : "";
                const LocalStartTime = post.start_time ? dayjs(post.start_time).local().format("DD/MM/YYYY HH:mm:ss") : "";
                const LocalEndTime = post.end_time ? dayjs(post.end_time).local().format("DD/MM/YYYY HH:mm:ss") : "";

                const row = {
                    "Schedule No": index === 0 ? schedule.schedule_no : "",
                    "Schedule Date/Time": index === 0 ? LocalScheduleDate : "",
                    "Common Description": index === 0 ? schedule.description || "" : "",
                    "Common First Comment": index === 0 ? schedule.comment_description || "" : "",
                    "Is Draft": index === 0 ? schedule.is_draft : "",
                    "Need Approval": index === 0 ? schedule.is_approval || "" : "",
                    "Errors": post.errors.join(" | "),
                    "Connected Profile Id": post.connected_profile_id,
                    "Connected Profile Name": post.connected_profile_details?.connected_profile_name || "",
                    "Social Media": post.connected_profile_details?.social_media || "",
                    "Description": post.description || "",
                    "First Comment": post.comment_description || "",
                    "Post Type": post.post_type,
                    "Youtube Privacy": SocialMedia === "youtube" ? post.privacy : "",
                    "Youtube Title": SocialMedia === "youtube" ? post.title : "",
                    "Youtube Thumbnail": errors.length > 0 ? post?.existing_thumbnail : post?.thumbnail,
                    "Pinterest Title": SocialMedia === "pinterest" ? post.title : "",
                    "Pinterest Boards": post.board_id || "",
                    "Pinterest Link": post.link || "",
                    "Pinterest Cover Image": errors.length > 0 ? post?.existing_cover_image_url : post?.cover_image_url,
                    "Gbusiness Button Type": SocialMedia === "gbusiness" ? post.button_type : "",
                    "Gbusiness Url": SocialMedia === "gbusiness" && (PostType === PostUtil.PostType.STANDARD?.toLowerCase() || PostType === PostUtil.PostType.EVENT?.toLowerCase()) ? post.url : "",
                    "Gbusiness Title": SocialMedia === "gbusiness" ? post.title : "",
                    "Gbusiness Start Date": SocialMedia === "gbusiness" && LocalStartDate || "",
                    "Gbusiness End Date": SocialMedia === "gbusiness" && LocalEndDate || "",
                    "Gbusiness Start Time": SocialMedia === "gbusiness" && LocalStartTime || "",
                    "Gbusiness End Time": SocialMedia === "gbusiness" && LocalEndTime || "",
                    "Gbusiness Offer Code": SocialMedia === "gbusiness" && post.offer_code || "",
                    "Gbusiness Offer Link": SocialMedia === "gbusiness" && PostType === PostUtil.PostType.OFFER?.toLowerCase() ? post.url : "",
                    "Gbusiness Terms & Conditions": SocialMedia === "gbusiness" && post.terms_conditions || "",
                    "TikTok Privacy": SocialMedia === "tiktok" ? post.privacy : "" || "",
                    "TikTok Comments": post.allow_user_to_comment || "",
                    "TikTok Duet": post.allow_user_to_duet || "",
                    "TikTok Stitch": post.allow_user_to_switch || "",
                    "Tiktok Your Brand": post.your_brand || "",
                    "Tiktok Branded Content": post.branded_content || "",
                };
                if (errors.length > 0) {
                    ExistingUrls.forEach((url, i) => {
                        row[`Media Url ${i + 1}`] = url || "";
                    });
                } else {
                    mediaUrls.forEach((url, i) => {
                        row[`Media Url ${i + 1}`] = url || "";
                    });
                }
                rows.push(row);
            });
        });
        return rows;
    };

    const generateCsvFile = (rows) => {
        const staticHeaders = [
            "Schedule No", "Schedule Date/Time", "Common Description", "Common First Comment", "Is Draft", "Need Approval", "Errors",
            "Connected Profile Id", "Connected Profile Name", "Social Media", "Description", "First Comment",
            "Post Type", "Youtube Privacy", "Youtube Title", "Youtube Thumbnail", "Pinterest Title", "Pinterest Boards",
            "Pinterest Link", "Pinterest Cover Image", "Gbusiness Button Type", "Gbusiness Url", "Gbusiness Title",
            "Gbusiness Start Date", "Gbusiness End Date", "Gbusiness Start Time", "Gbusiness End Time", "Gbusiness Offer Code",
            "Gbusiness Offer Link", "Gbusiness Terms & Conditions", "TikTok Privacy", "TikTok Comments", "TikTok Duet",
            "TikTok Stitch", "Tiktok Your Brand", "Tiktok Branded Content",
        ];

        const maxMediaCount = rows.reduce((max, row) => {
            const mediaKeys = Object.keys(row).filter(key => key.startsWith("Media Url"));
            return Math.max(max, mediaKeys.length);
        }, 0);

        const mediaHeaders = Array.from({ length: maxMediaCount }, (_, i) => `Media Url ${i + 1}`);

        const headers = [...staticHeaders, ...mediaHeaders];

        const escapeCsvField = (field) => {
            if (typeof field === 'string' && (field.includes(',') || field.includes('\n') || field.includes('"'))) {
                return `"${field.replace(/"/g, '""')}"`;
            }
            return field;
        };

        const csvContent = [
            headers.join(","),
            ...rows.map(row =>
                headers.map((header) => escapeCsvField(row[header] || "")).join(",")
            ),
        ].join("\n");

        downloadCsv(csvContent);
    };

    const downloadCsv = (csvContent) => {
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const filename = "B2s_ErrorTemplate.csv";
            link.setAttribute("href", URL.createObjectURL(blob));
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleDownloadCsvFile = () => {
        const rows = mapJsonToCsvRows(jsonData);
        generateCsvFile(rows);
    };

    return (
        <Dialog open maxWidth="lg" fullWidth onClick={(e) => { e.stopPropagation() }} PaperProps={{
            style: {
              minHeight: '300px',
              maxHeight: '90vh',
              height: '90vh'
            },
          }}>
            <DialogTitle>Error Details</DialogTitle>
            <DialogContent>
                {errors.length > 0 ? (
                    <Box>
                        {errors.map((err, index) => (
                            <Box key={index} style={{ marginBottom: '0.5rem', borderBottom: `1px solid ${theme.palette.text.disabled}` }}>
                                <Typography sx={{ fontWeight: 600 }}>An Error occurred in Schedule No. {err.schedule_no} on row {err.postIndex}</Typography>
                                {err.post.errors.map((error) => (
                                    <TextIconLabel
                                        icon={<ErrorOutlineIcon sx={{ color: theme.palette.error.main, mr: 1, fontSize: 18 }} />}
                                        value={error}
                                        sx={{ typography: 'caption', color: theme.palette.error.main, mb: 1, fontWeight: 800, fontSize: 14 }}
                                    />
                                ))}
                                <Box sx={{ width: '100%', overflowX: 'auto' }}>
                                    <TableContainer component={Paper} sx={{ width: '100%' }}>
                                        <Table stickyHeader aria-label="error table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Profile</TableCell>
                                                    <TableCell align="center">Profile Name</TableCell>
                                                    <TableCell align="center">Social Media</TableCell>
                                                    <TableCell align="center">Post Type</TableCell>
                                                    <TableCell align="left">Media</TableCell>
                                                    <TableCell align="left">Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Array.isArray(err.post) ? (
                                                    err.post.map((post) => (
                                                        <TableRow key={post.id || post.media_url}>
                                                            <TableCell align="left">
                                                                {PostDetailsHelper.getSocialMediaViewWithBadge(
                                                                    err.post?.connected_profile_details || {},
                                                                    err.post?.connected_profile_details?.social_media || "Unknown"
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {err.post?.connected_profile_details?.connected_profile_name || "No Name"}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {err.post?.connected_profile_details?.social_media || "No Social Media"}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {err.post?.post_type || "No Post Type"}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {PostDetailsHelper.getPostMedias(err.post?.media_url || [])}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {PostDetailsHelper.GetPostContent(err.post?.description || "No Description", 400)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            {PostDetailsHelper.getSocialMediaViewWithBadge(
                                                                err.post?.connected_profile_details || {},
                                                                err.post?.connected_profile_details?.social_media || "Unknown"
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {err.post?.connected_profile_details?.connected_profile_name || "No Name"}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {err.post?.connected_profile_details?.social_media || "No Social Media"}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {err.post?.post_type || "No Post Type"}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {PostDetailsHelper.getPostMedias(err.post?.media_url || [])}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {PostDetailsHelper.GetPostContent(err.post?.description || "No Description", 400)}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Box>No errors found!</Box>
                )
                }
            </DialogContent>
            <DialogActions
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}
            >
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button variant="outlined" color="error"
                        onClick={(event) => {
                            event.stopPropagation();
                            onClose();
                        }}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={(event) => {
                            event.stopPropagation();
                            handleDownloadCsvFile();
                        }}>
                        Download Error file
                    </Button>
                </div>
            </DialogActions>
        </Dialog >
    )
}
export default CsvFileErrorPopup;