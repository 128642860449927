import { useTheme } from '@emotion/react';
import { Hidden, ListItemText, TableBody, useMediaQuery,Tabs,Box,Tab } from '@mui/material';
import { useState } from 'react';
import { AccountBoxSharp as AccountBoxSharpIcon } from '@mui/icons-material';
import PostReviewComments from 'src/pages/scheduledPost/Preview/post-preview-comment.component';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import PostPreview from '../Preview/post-preview.component';
import ScheduledPostGridAction from './PostGridAction.component';

const ScheduledPostGridBody = ({ scheduledPostList, page, rowsPerPage, handleReCallScheduledPost }) => {

  const theme = useTheme();
  const { showToast } = useToaster();
  const { showRightSidebar } = useRightSidebar();
  let maxWidth = useMediaQuery(theme.breakpoints.down('sm')) ? 500 : 400;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const POPUP_WIDTH = isMobile ? '300px' : '500px';

  const handleOpenScheduledPost = (row, open = false) => {
    let data = {
      content: postView,
      data: row,
    };
    showRightSidebar(data, POPUP_WIDTH);
  };

  const postView = (row) => {
    let postDetails = {
        data: { id: row.id },
    };
    return <PublicDetailsPreview postDetails={postDetails} />;
};
  const ACCOUNT_TABS = (postDetails) => [
    {
        value: 'Preview Event',
        icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
        component: <PostPreview postDetails={postDetails}  />,
    },
    {
        value: 'Review Comments',
        icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
        component: <PostReviewComments PostDetails={postDetails} />,
    }
];

  const PublicDetailsPreview = ({ postDetails }) => {
    const tabs = ACCOUNT_TABS(postDetails);
    const [currentTab, setCurrentTab] = useState(tabs[0].value);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Box>
            <Tabs
                value={currentTab}
                onChange={handleChange}
                scrollButtons="auto"
                variant="scrollable"
                sx={{ gap: '0.5rem', minHeight: '37px' }}
                allowScrollButtonsMobile
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.value}
                        value={tab.value}
                        label={tab.value}
                        icon={tab.icon}
                        sx={{
                            flexDirection: 'row',
                            gap: '0.25rem',
                            padding: 1,
                            minHeight: '10px',
                            mr: '0.75rem',
                            ml: '0.75rem',
                            fontWeight: 600,
                        }}
                        disableRipple
                    />
                ))}
            </Tabs>
            <Box sx={{ mb: 2 }} />
            <Box>
                {tabs.find((tab) => tab.value === currentTab)?.component}
            </Box>
        </Box>
    );
};
  const handleToggleScheduledPost = (event, scheduledPost) => {
    event.stopPropagation();
    let bodyProps = {
      schedule_post_id: scheduledPost.id,
    };
    let postProps = {
      url: scheduledPost.is_enabled ? userModuleURL.disableScheduledPost : userModuleURL.enableScheduledPost,
      body: bodyProps,
      successCallback: toggleScheduledPostSuccessCallback,
      failureCallback: toggleScheduledPostFailureCallback,
    };
    HttpServices.Post(postProps);
  };

  const toggleScheduledPostSuccessCallback = (data, message) => {
    showToast(message, 's');
    handleReCallScheduledPost();
  };

  const toggleScheduledPostFailureCallback = (message) => {
    showToast(message, 'e');
    handleReCallScheduledPost();
  };

  return (
    <TableBody>
      {scheduledPostList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
        <StyledComponent.StyledTableRow key={i} onClick={() => handleOpenScheduledPost(row, true)}>
          <StyledComponent.StyledTableCell>
            {CommonHelper.getTableDateAndTime(row.scheduled_time)}
          </StyledComponent.StyledTableCell>
          <Hidden only={['xs']}>
            <StyledComponent.StyledTableCell>
              <ListItemText
                sx={{
                  m: 0,
                  '.MuiListItemText-primary': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '0.875rem',
                  },
                  '.MuiListItemText-secondary': {
                    color: '#637381',
                    fontSize: '0.75rem',
                    textTransform: 'lowercase',
                  },
                }}
                primary={CommonHelper.formatDateAndTime(row.created_time, 'date')}
                secondary={CommonHelper.formatDateAndTime(row.created_time, 'time')}
              />
            </StyledComponent.StyledTableCell>
          </Hidden>
          <Hidden only={['xs']}>
            <StyledComponent.StyledTableCell>
              <ListItemText
                sx={{
                  m: 0,
                  '.MuiListItemText-primary': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '0.875rem',
                  },
                  '.MuiListItemText-secondary': {
                    color: '#637381',
                    fontSize: '0.75rem',
                    textTransform: 'lowercase',
                  },
                }}
                primary={CommonHelper.formatDateAndTime(row.updated_at, 'date')}
                secondary={CommonHelper.formatDateAndTime(row.updated_at, 'time')}
              />
            </StyledComponent.StyledTableCell>
          </Hidden>
          <StyledComponent.StyledTableCell>
            {PostDetailsHelper.GetPostContent(row.description, maxWidth)}
          </StyledComponent.StyledTableCell>
          <Hidden only={['xs']}>
            <StyledComponent.StyledTableCell onClick={(event) => event.stopPropagation()}>
              {PostDetailsHelper.getPostMedias(row.media_url+"_thumbnail.jpg")}
            </StyledComponent.StyledTableCell>
          </Hidden>
          <StyledComponent.StyledTableCell>
            {PostDetailsHelper.getProfile(row.created_by.name, row.created_by.email, row.created_by.profile_pic)}
          </StyledComponent.StyledTableCell>
          <StyledComponent.StyledTableCell>
            {PostDetailsHelper.getProfile(row.approved_by.name, row.approved_by.email, row.approved_by.profile_pic)}
          </StyledComponent.StyledTableCell>
          <StyledComponent.StyledTableCell>
            <StyledComponent.SwitchIcon
              disabled={!row.has_edit_access}
              checked={row.is_enabled}
              onClick={(event) => handleToggleScheduledPost(event, row)}
            />
          </StyledComponent.StyledTableCell>
          <Hidden only={['xs']}>
            <StyledComponent.StyledTableCell>
              {PostDetailsHelper.getSocialMediaViewWithTooltip(row.connected_profile_details)}
            </StyledComponent.StyledTableCell>
          </Hidden> 
          <StyledComponent.StyledTableCell  onClick={(event) => event.stopPropagation()} align='center'>
              <ScheduledPostGridAction scheduledPost={row} handleReCallScheduledPost={handleReCallScheduledPost} />
          </StyledComponent.StyledTableCell>
        </StyledComponent.StyledTableRow>
      ))}
    </TableBody>
  );
};

export default ScheduledPostGridBody;
