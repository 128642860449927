import React from 'react';
import { Box, Card, CardContent, Avatar, Typography } from '@mui/material';

const ProfileCard = ({ primaryText, secondaryText, imageUrl }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 6, position: 'relative' }}>
      <Card sx={{ maxWidth: 345, padding: 1, borderRadius: 4, boxShadow: 3 }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            alt={primaryText}
            src={imageUrl || ''}
            sx={{ width: 80, height: 80, marginRight: 2, bgcolor: 'primary.main', fontSize: 32 }}
          >
            {!imageUrl && primaryText.charAt(0)}
          </Avatar>
          <Box>
            <Typography variant="h6"
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis'}}
            >{primaryText}</Typography>
            <Typography variant="body2" color="textSecondary"
              sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 5,
              overflow: 'hidden',
              textOverflow: 'ellipsis',}}>
            {secondaryText}
          </Typography>
        </Box>
      </CardContent>
    </Card>
    </Box >
  );
};

export default ProfileCard;
