import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
const ConfirmationDailogDisintegrate = ({ open, onClose, onConfirm, title, message,loading, cancelButtonName = 'Cancel', submitButtonName = 'Submit' }) => {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={() => onClose()}>{cancelButtonName}</Button>
        <LoadingButton variant="contained" loading={loading} onClick={() => {
          onConfirm();
        }} autoFocus>
          {submitButtonName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDailogDisintegrate;