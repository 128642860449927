import { Box, Popover, Typography, CircularProgress, Stack } from '@mui/material';
import { PictureAsPdf, Image, Email } from '@mui/icons-material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { reportModuleURL } from 'src/services/urlService';
import HttpServices from 'src/services/httpService';
import SendAsEmailComponent from './sendAsEmail.component';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import React from 'react';
import { useSnackbar } from 'notistack';
import ScheduledReportView from './scheduledReportView.component';

const ReportActionPopup = ({ open, anchorEl, onClose, serviceName, startDate, endDate, viewBy, connectedProfileId, reportId, subReportId = 0 ,currReportData}) => {
    const { showToast } = useToaster();
    let currentBrandId = CommonHelper.GetCurrentBrandId();
    const { showRightSidebar } = useRightSidebar();
    const { enqueueSnackbar } = useSnackbar();
    const getExportReport = (type) => {
        const formBody = {
          from: startDate,
          to: endDate,
          view_by: viewBy,
          type: type,
          report_id: reportId,
          sub_report_id: subReportId,
          service_name: serviceName,
          connected_profile_id: connectedProfileId,
          brand_id: currentBrandId,
        };
    
        enqueueSnackbar('Generating report...', { variant: 'info' });
    
        const successCallback = (data) => {
          if (data?.report_url) {
            // Trigger file download
            const link = document.createElement('a');
            link.href = data.report_url;
            link.download = currReportData.report_display_name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            enqueueSnackbar('Report generated successfully!', { variant: 'success' });
          }
        };
    
        const failureCallback = (message) => {
          enqueueSnackbar(message || 'Failed to generate report.', { variant: 'error' });
        };
    
        const getProps = {
          url: reportModuleURL.exportReport,
          body: formBody,
          successCallback,
          failureCallback,
        };
    
        HttpServices.Post(getProps);
      };
    
    
    const handleOpenPost = () => {
        let data = {
            content:sideBarView,
        };
        showRightSidebar(data);
        onClose();
    };
    
    const sideBarView = () => {
        let emailDetails = {
            serviceName: serviceName,
            startDate: startDate,
            endDate:endDate,
            viewBy:viewBy,
            connectedProfileId:connectedProfileId,
            reportId:reportId,
            subReportId:subReportId,
            currReportData,
        };
        return (<SendAsEmailComponent {...emailDetails}/>);
    };
    const handleOpenSceduled = () => {
        let data = {
            content:sideBarSheduledView,
        };
        showRightSidebar(data);
        onClose();
    };
    
    const sideBarSheduledView = () => {
        let emailDetails = {
            serviceName: serviceName,
            startDate: startDate,
            endDate:endDate,
            viewBy:viewBy,
            connectedProfileId:connectedProfileId,
            reportId:reportId,
            subReportId:subReportId,
            currReportData,
        };
        return (<ScheduledReportView {...emailDetails}/>);
    };


    // const getExportReport = (type) => {
    //     setLoading(true);
    //     const formBody = {
    //         from: startDate,
    //         to: endDate,
    //         view_by: viewBy,
    //         type: type,
    //         report_id: reportId,
    //         sub_report_id: subReportId,
    //         service_name: serviceName,
    //         connected_profile_id: connectedProfileId,
    //     };

    //     const getProps = {
    //         url: reportModuleURL.exportReport,
    //         body: formBody,
    //         successCallback,
    //         failureCallback,
    //     };
    //     HttpServices.Post(getProps);
    // };

    return (
        <>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {
                    width: 230,
                    borderRadius: 1,
                    boxShadow: 3,
                    //display:'none',
                },
            }}
        >
            <Box sx={{ p: 1, textAlign: 'center' }}>
               
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                cursor: 'pointer',
                                padding: '8px 16px',
                                borderRadius: 1,
                                '&:hover': { backgroundColor: 'action.hover' },
                                mb: 1,
                            }}
                            onClick={() => getExportReport("PDF")}
                        >
                            <PictureAsPdf color="action" />
                            <Typography variant="body1">Download as PDF</Typography>
                        </Stack>
                        {/* <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                cursor: 'pointer',
                                padding: '8px 16px',
                                borderRadius: 1,
                                '&:hover': { backgroundColor: 'action.hover' },
                                mb: 1,
                            }}
                            onClick={() => getExportReport("PNG")}
                        >
                            <Image color="action" />
                            <Typography variant="body1">Download as PNG</Typography>
                        </Stack> */}
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                cursor: 'pointer',
                                padding: '8px 16px',
                                borderRadius: 1,
                                '&:hover': { backgroundColor: 'action.hover' },
                            }}
                            onClick={handleOpenPost}
                        >
                            <Email color="action" />
                            <Typography variant="body1">Send as Email</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                cursor: 'pointer',
                                padding: '8px 16px',
                                borderRadius: 1,
                                '&:hover': { backgroundColor: 'action.hover' },
                            }}
                            onClick={handleOpenSceduled} 
                        >
                            <ScheduleIcon color="action" />
                            <Typography variant="body1">Schedule</Typography>
                        </Stack>
                    
            </Box>
        </Popover>
        
    </>
    );
};

export default ReportActionPopup;
