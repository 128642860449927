import { Card, FormControl, InputLabel, MenuItem, Select, Typography, Box, useTheme, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const ScheduledPostFilter = ({ handleApplyFilter, fromDate, toDate }) => {
    const [filterTypes, setFilterTypes] = useState(["This Year", "Last week", "Last month", "Last 3 months", "Last 6 months", "Last 1 year", "Custom"]);
    const [selectedFilterType, setFilterType] = useState("This Year");
    const [startDate, setStartDate] = useState(fromDate);
    const [endDate, setEndDate] = useState(toDate);
    const [customStartSelected, setCustomStartSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const theme = useTheme();

    const handleFilterChange = (event) => {
        let value = event.target.value;
        setFilterType(value);
        setDateBasedOnFilterType(value);
    }

    const setDateBasedOnFilterType = (type) => {
        let currentDate = new Date();
        let sDate = startDate;
        let eDate = endDate;
        currentDate.setHours(0, 0, 0, 0);

        switch (type.toLowerCase()) {
            case "this year":
                sDate = new Date(currentDate.getFullYear(), 0, 1); // Start of the year
                eDate = new Date(currentDate.getFullYear(), 11, 31); // End of the year
                break;
            case "last week":
                sDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
                eDate = new Date();
                break;
            case "last month":
                sDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
                eDate = new Date();
                break;
            case "last 3 months":
                sDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
                eDate = new Date();
                break;
            case "last 6 months":
                sDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
                eDate = new Date();
                break;
            case "last 1 year":
                sDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
                eDate = new Date();
                break;
            case "custom":
                sDate = null;
                eDate = null;
                setCustomStartSelected(false);
                break;
            default:
                break;
        }
        setStartDate(sDate);
        setEndDate(eDate);
        if (sDate && eDate && type.toLowerCase() !== "custom") {
            handleApplyFilter(sDate, eDate);
        }
    }

    const handleChangeDate = (date, type) => {
        setErrorMessage(""); // Clear any previous error messages

        if (type === "fromdate") {
            setStartDate(date);
            setEndDate(null); // Reset end date when start date changes
            setCustomStartSelected(true);
        } else if (type === "todate") {
            setEndDate(date);
        }

        // Check if dates are valid and apply filter
        if (type === "todate" && checkForDateValidation(startDate, date)) {
            handleApplyFilter(startDate, date);
        }
    };

    const checkForDateValidation = (startDate, endDate) => {
        if (!startDate || !endDate) {
            setErrorMessage("Please select both start and end dates.");
            return false;
        }
        if (new Date(startDate) > new Date(endDate)) {
            setErrorMessage("Start date cannot be after the end date.");
            return false;
        }
        return true;
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }} >
            <FormControl sx={{ minWidth: 150 }} >
                <InputLabel id="date_filter">Choose Date Filter</InputLabel>
                <Select
                    value={selectedFilterType}
                    label="Choose Date Filter"
                    onChange={handleFilterChange}
                >
                    {filterTypes.map(filter => (
                        <MenuItem value={filter} key={filter}>{filter}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Stack direction="row" sx={{ alignItems: 'center', gap: '1rem' }} >
                {selectedFilterType === "Custom" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => handleChangeDate(newValue, "fromdate")}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => handleChangeDate(newValue, "todate")}
                            minDate={startDate || null}
                            disabled={!customStartSelected}
                        />
                    </LocalizationProvider>
                )}
            </Stack>

            {errorMessage && (
                <Typography sx={{ color: 'error.main', fontSize: '0.875rem' }}>
                    {errorMessage}
                </Typography>
            )}

            {startDate && endDate && 
                <Stack direction="row" sx={{ alignItems: 'center', gap: 1 }} >
                    <CalendarMonthIcon sx={{ color: 'primary.main' }} />
                    <Typography >
                        Filter applied from  <span
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                color: theme.palette.primary.main,
                                marginLeft: '4px',
                                fontWeight: 600
                            }}
                        >{CommonHelper.formatDateAndTime(startDate, "date")}</span> to
                        <span
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                color: theme.palette.primary.main,
                                marginLeft: '4px',
                                fontWeight: 600
                            }}
                        >{CommonHelper.formatDateAndTime(endDate, "date")}</span>
                    </Typography>
                </Stack>
            }
        </Box >
    );
}

export default ScheduledPostFilter;
