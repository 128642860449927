import { TextField, Typography, IconButton, Tooltip, InputAdornment, Box, Divider } from '@mui/material';
import { PhotoCameraOutlined as PhotoCameraOutlinedIcon, AutoAwesome as AIIcon, DriveFolderUpload as DriveFolderUploadIcon, Tag as TagIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useThrottle from 'src/utils/throttle/throttle.component';

const PostDescription = ({ handleChangeDescription, valueDescription, handleUploadMediaPopup, handleBulkMediaPopup, showInputProps, handleAIContent, handleGenerateHashtags }) => {
  const theme = useTheme();

  // State Variables
  const [description, setDescription] = useState('');

  useEffect(() => {
    setDescription(valueDescription);
  }, [valueDescription]);

  const throttledChangeDescription = useThrottle((value) => {
    handleChangeDescription(value);
  }, 500);

  const handleDescription = (value) => {
    setDescription(value);
    throttledChangeDescription(value);
  };


  return (
    <>
      <Box position="relative" style={{ paddingTop: showInputProps && '1rem' }}>
        <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2 }}>
          Description
        </Typography>
        {showInputProps ?
          <TextField
            name="description"
            placeholder="Enter Post Content"
            fullWidth
            multiline
            rows={showInputProps ? 6 : 4}
            onChange={(event) => {
              handleDescription(event.target.value);
            }}
            value={description}
            sx={{
              bgcolor: 'background.neutral',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              borderBottomLeftRadius: !showInputProps && 8,
              borderBottomRightRadius: !showInputProps && 8,
              borderBottom: showInputProps
                ? `1px solid ${theme.palette.background.neutral}`
                : `1px solid ${theme.palette.text.border}`,
              borderTop: `1px solid ${theme.palette.text.border}`,
              borderLeft: `1px solid ${theme.palette.text.border}`,
              borderRight: `1px solid ${theme.palette.text.border}`,
            }}
          /> :
          <textarea
            placeholder="Enter Post Content"
            rows="4"
            style={{
              width: '100%',
              backgroundColor: theme.palette.background.neutral,
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              borderBottomLeftRadius: showInputProps ? '0' : '8px',
              borderBottomRightRadius: showInputProps ? '0' : '8px',
              borderTop: `1px solid ${theme.palette.text.border}`,
              borderLeft: `1px solid ${theme.palette.text.border}`,
              borderRight: `1px solid ${theme.palette.text.border}`,
              borderBottom: showInputProps
                ? `1px solid ${theme.palette.background.neutral}`
                : `1px solid ${theme.palette.text.border}`,
              outline: 'none',
              padding: '1rem',
              fontFamily: 'sans-serif',
              fontSize: '1rem',
            }}
            onChange={(event) => handleDescription(event.target.value)}
            value={description}
          />
        }
        {showInputProps && (
          <Box
            sx={{
              bottom: '7rem',
              left: '1.5rem',
              right: '1.5rem',
              display: 'flex',
              backgroundColor: 'white',
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              border: '1px solid',
              borderTopColor: 'background.neutral',
              borderBottomColor: theme.palette.text.border,
              borderLeftColor: theme.palette.text.border,
              borderRightColor: theme.palette.text.border,
              // mb: 2,
            }}
          >
            <Tooltip title="Upload Media">
              <IconButton
                onClick={() => {
                  handleUploadMediaPopup(true);
                }}
              >
                <PhotoCameraOutlinedIcon style={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Bulk Upload">
              <IconButton
                onClick={() => {
                  handleBulkMediaPopup(true);
                }}
              >
                <DriveFolderUploadIcon style={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="AI Content">
              <Box
                onClick={() => {
                  handleAIContent(true);
                }}
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: theme.palette.primary.main, '&:hover': { color: theme.palette.primary.dark } }} // Applied color by default and brighter on hover
              >
                <AIIcon style={{ fontSize: '1.5rem' }} />
                <Typography
                  variant="body2"
                  sx={{ ml: 1, fontWeight: 'bold' }}
                >
                  (Free AI Content Generator)
                </Typography>
              </Box>
            </Tooltip>
            <Tooltip title="Generate Hashtags">
              <Box
                onClick={handleGenerateHashtags}
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: theme.palette.primary.main, '&:hover': { color: theme.palette.primary.dark }, ml: 2 }} // Applied color by default and brighter on hover
              >
                <TagIcon style={{ fontSize: '1.5rem' }} />
                <Typography
                  variant="body2"
                  sx={{ ml: 1, fontWeight: 'bold' }}
                >
                  AI Hashtags
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
      {/* <Tooltip title="Upload Media">
        <IconButton
        style={{bottom:0, left:0, position: 'absolute'}}
          // onClick={() => {
          //   handleUploadMediaPopup(true);
          // }}
        >
          <PhotoCamera />
        </IconButton>
      </Tooltip> */}
    </>
  );
};
export default PostDescription;
