import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import UserListHead from '../../sections/@dashboard/user/UserListHead';
import PendingUserAction from './pendingUserAction.component';
import { CommonHelper } from 'src/utils/commonHelper';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import NoPendingUser from '../../assets/app-image/no-pending-users.svg';

const PendingUsers = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [height, setHeight] = useState(window.innerHeight - 150);
  const [loader, setLoader] = useState(true);
  const [selectedPopOverPost, setPopOverPost] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [popoverElement, setPopoverElement] = useState(null);
  const { showToast } = useToaster();
  const isOrgUser = CommonHelper.CheckForPermissionAvailableForUser();

  useEffect(() => {
    getAllPendingInvites();
  }, []);

  const getAllPendingInvites = () => {
    let postProps = {
      url: userModuleURL.getAllPendingInvites,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(postProps);
  };
  const successCallback = (data, message) => {
    setUsers(data);
    setLoader(false);
    handleCloseMenu();
  };
  const failureCallback = (message) => {
    setLoader(false);
    handleCloseMenu();
    message && showToast(message, 'e');
  };
  const handleOpenMenu = (event, row) => {
    setPopOverPost(row);
    setPopoverElement(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setPopOverPost(null);
    setPopoverElement(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const deleteUserSuccessCallback = (data, message) => {
    message && showToast(message, 's');
    handleCloseMenu();
    getAllPendingInvites();
  };
  const resendInviteSuccessCallback = (data, message) => {
    message && showToast(message, 's');
    handleCloseMenu();
  };
  const handleOpenConfirm = (action) => {
    setCurrentAction(action);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setCurrentAction(null);
  };

  const handleConfirmAction = () => {
    if (currentAction === 'delete') {
      handleDeletePendingInvite();
    } else if (currentAction === 'resend') {
      handleResendPendingInvite();
    }
    handleCloseConfirm();
  };
  const handleDeletePendingInvite = () => {
    let bodyProps = {
      email: selectedPopOverPost.email,
    };
    let postProps = {
      url: userModuleURL.deleteInvite,
      body: bodyProps,
      successCallback: deleteUserSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleResendPendingInvite = () => {
    let bodyProps = {
      email: selectedPopOverPost.email,
    };
    let postProps = {
      url: userModuleURL.resendInvite,
      body: bodyProps,
      successCallback: resendInviteSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const pendingUserActionProps = {
    popoverElement: popoverElement,
    handleCloseMenu,
    handleDeletePendingInvite,
    handleOpenConfirm,
    handleResendPendingInvite,
    selectedPopOverPost: selectedPopOverPost,
  };
  const checkForOrgPermission = (user) => {
    return user?.is_org_user ? isOrgUser : true;
  };
  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <>
          {users?.length > 0 ? (
            <Card sx={{ m: 1}}>
                  <TableContainer
                    sx={{
                      minHeight: 'calc(100vh - 305px)', maxHeight: 'calc(100vh - 305px)'
                    }}
                  >
                    <Table aria-label="collapsible table" stickyHeader>
                      <UserListHead
                        headLabel={[
                          { id: 'name', label: 'Team Member', alignRight: false },
                          { id: 'email', label: 'Email', alignRight: false },
                          { id: 'role', label: 'Role', alignRight: false },
                          { id: 'brand', label: 'Brand', alignRight: false },
                          { id: 'action', label: 'Action', alignRight: false },
                        ]}
                        rowCount={users.length}
                        isShowCheckbox={false}
                      />
                      <TableBody>
                        {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                          <TableRow hover key={user.id} tabIndex={-1}>
                            <TableCell align="left">
                              <Typography>{user.name}</Typography>
                            </TableCell>
                            <TableCell align="left">{user.email}</TableCell>
                            <TableCell align="left">
                              <Label color="primary" style={{ margin: 0.5 }}>
                                {user.role_name}
                              </Label>
                            </TableCell>
                            <TableCell align="left">
                              <Label color="primary" style={{ margin: 0.5 }}>
                                {user.brand_name}
                              </Label>
                            </TableCell>
                            <TableCell align="left">
                              {checkForOrgPermission() && (
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(e) => {
                                    handleOpenMenu(e, user);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 15, 25]}
                    count={users?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              <ConfirmationDialog
                open={confirmOpen}
                onClose={handleCloseConfirm}
                onConfirm={handleConfirmAction}
                title={`Confirm ${currentAction === 'delete ' ? 'Delete Invite' : 'Resend Invite'}`}
                message={`Are you sure you want to ${
                  currentAction === 'delete' ? 'delete the invite' : 'resend the invite'
                }?`}
              />
            </Card>
          ) : (
              <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height: height,minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)'}}>
                <img src={NoPendingUser} style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }} alt="No Pending User"/>
              </Card>
          )}
          <PendingUserAction {...pendingUserActionProps} />
        </>
      )}
    </>
  );
};

export default PendingUsers;
