import { PostUtil } from "../new-post-new/post-util";
let baseURL =  process.env.REACT_APP_REDIRECT_URL;

const CustomDomainRedirectIframe = ({ serviceName }) => {
    const GetRedirectUrl = () => {
        let sourceFile = null;
        switch (serviceName.toUpperCase().trim()) {
            case PostUtil.ServiceName.TWITTER:
                sourceFile = baseURL + '/twitter-redirect';
                break;
            case PostUtil.ServiceName.FACEBOOK:
                sourceFile =  baseURL + '/facebook-redirect';
                break;
            case PostUtil.ServiceName.YOUTUBE:
                sourceFile =  baseURL + '/youtube-redirect';
                break;
            case PostUtil.ServiceName.INSTAGRAM:
                sourceFile =  baseURL + '/facebook-redirect';
                break;
            case PostUtil.ServiceName.TIKTOK:
                sourceFile =  baseURL + '/tiktok-redirect';
                break;
            case PostUtil.ServiceName.LINKEDIN:
                sourceFile =  baseURL + '/linkedin-redirect';
                break;
            case PostUtil.ServiceName.GBUSINESS:
                sourceFile =  baseURL + '/gbusiness-redirect';
                break;
            case PostUtil.ServiceName.PINTEREST:
                sourceFile =  baseURL + '/pinterest-redirect';
                break;
            default:
                break;
        }
        return sourceFile;
    };

    const redirectUrl = GetRedirectUrl();

    if (!redirectUrl) {
        return null; // Return null if no valid redirect URL is available
    }

    return (
        <iframe
            id="iframe-id"
            src={redirectUrl}
            style={{ display: "none" }}
            title="Custom Authorization"
        ></iframe>
    );
};

export default CustomDomainRedirectIframe;