import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography, Select, MenuItem, Chip, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { FormProvider, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import LinearProgressBar from 'src/components/progress-bar/progressBar.component';
import HttpServices from 'src/services/httpService';
import { imageModeuleUrl, userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { LocalStorage } from 'src/utils/storage/local-storage';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ValidationTool from 'src/utils/validationHelper';

const OrgInfoComponent = () => {
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const maxFileSizeInBytes = 10485760;
  const supportedFileFormat = ['image/jpeg', 'image/png', 'image/jpg'];
  const isUserHasPermission = CommonHelper.CheckForPermissionAvailableForUser();
  const userOrgDetails = CommonHelper.GetBrandInfoFromLocalStorage();

  //State Variables
  const [orgName, setOrgName] = useState('');
  const [imageUrl, setOrgImage] = useState('');
  const [aiKey, setAIKey] = useState('');
  const [isLeaveOrgLoading, setLeaveOrgLoader] = useState(false);
  const [isDeleteOrgLoading, setDeleteOrgLoader] = useState(false);
  const [orgDescription, setOrgDescription] = useState('');
  const [formSchema, setFormSchema] = useState({});
  const [listOrgAdmins, setListOrgAdmins] = useState([]);
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [isSaveChangesLoading, setSaveChangesLoader] = useState(false);
  const [fileUploadprogress, setFileUploadprogress] = useState(0);
  const [selectedAdmin, setSelectedAdmin] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [hasAiKey, setHasAIKey] = useState(false);

  useEffect(() => {
    getOrganizationInfo();
    getAllOrganizationAdmins();
  }, []);

  useEffect(() => {}, [imageUrl]);

  const getOrganizationInfo = () => {
    let getProps = {
      url: userModuleURL.fetchOrgInfo,
      successCallback: brandSuccessCallBack,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getAllOrganizationAdmins = () => {
    let getProps = {
      url: userModuleURL.fetchOrgAdmins,
      successCallback: fetchOrgAdminsSuccessCallBack,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };
  const fetchOrgAdminsSuccessCallBack = (data, message) => {
    setListOrgAdmins(data);

    // Find the admin with is_portal_admin: true and set as selected
    const defaultAdmin = data.find((admin) => admin.is_portal_admin);
    if (defaultAdmin) {
      setSelectedAdmin(defaultAdmin.user_id); // Set the selected admin user_id as default
    }
  };
  const brandSuccessCallBack = (data, message) => {
    setOrgName(data.org_name);
    setOrgImage(data.org_icon_url);
    setOrgDescription(data.org_description);
    if (data.has_ai_key) {
      setAIKey('**************');
    }
    setHasAIKey(false);
  };
  const successCallback = (data, message) => {
    showToast(message, 's');
    setSaveChangesLoader(false);
    window.location.reload();
  };
  const failureCallback = (message) => {
    showToast(message, 'e');
    setSaveChangesLoader(false);
    setDeleteOrgLoader(false);
    setLeaveOrgLoader(false);
  };
  const handleSelectChange = (event) => {
    setSelectedAdmin(event.target.value);
  };

  const handleSubmitForm = () => {
    let userForm = {
      orgName: orgName,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, userForm);
    if (isValidForm) {
      setFormValidationErrors({});
      onSubmit();
    } else setFormValidationErrors(formValidationErrors);
  };
  const deleteOrgSuccessCallback = (data, message) => {
    CommonHelper.ClearAppLocalStorage();
    // navigate('/no-organization');
    window.location.reload();
  };
  const handleOpenConfirm = (action) => {
    setCurrentAction(action);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setCurrentAction(null);
  };

  const handleConfirmAction = () => {
    if (currentAction === 'delete') {
      handleDeleteOrg();
    } else if (currentAction === 'remove') {
      handleLeaveOrg();
    }
    handleCloseConfirm(); // Close the confirmation dialog after confirming action
  };
  const handleDeleteOrg = () => {
    setDeleteOrgLoader(true);
    CommonHelper.DeleteOrganization(deleteOrgSuccessCallback, failureCallback);
  };
  const leaveOrgSuccessCallback = (data, message) => {
    navigate('/no-organization', { replace: true });
  };
  const handleLeaveOrg = () => {
    setLeaveOrgLoader(true);
    let postProps = {
      url: userModuleURL.leaveOrganization,
      successCallback: leaveOrgSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const onSubmit = () => {
    let brandProps = {
      org_name: orgName,
      org_icon_url: imageUrl,
      org_description: orgDescription,
      portal_owner_user_id: selectedAdmin,
      ai_key: aiKey,
      has_ai_key: hasAiKey,
    };
    setSaveChangesLoader(true);
    let postProps = {
      url: userModuleURL.updateOrg,
      body: brandProps,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      handleFileUpload(file);
    } else {
      showToast('We only support JPEG, and PNG file formats. The selected file are not in the supported format', 'e');
    }
  };
  const fileUploadSuccessCallback = (data, message) => {
    setOrgImage(data.media_data[0].media_url);
  };
  const handleFileUpload = (file) => {
    let isFileInCorrectFormat = supportedFileFormat.some((a) => a.includes(file.type));
    if (isFileInCorrectFormat) {
      if (file.size < maxFileSizeInBytes) {
        setFileUploadprogress(1);
        let formData = new FormData();
        formData.append('fileupload', file);
        axios
          .post(imageModeuleUrl.uploadMedia, formData, {
            headers: HttpServices.GetRequestHeader('form', true),
            onUploadProgress: (data) => {
              let loadedProgress = Math.round((100 * data.loaded) / data.total);
              setFileUploadprogress(loadedProgress > 90 ? 90 : loadedProgress);
            },
          })
          .then((result) => result)
          .then((response) => {
            setFileUploadprogress(100);
            if (response.status === 200 && response.data.http_code === 200)
              fileUploadSuccessCallback(response.data.data, 'File Uploaded Successfully');
            else showToast('Something went wrong. Please contact our technical team', 'e');
            setFileUploadprogress(0);
          })
          .catch((error) => {
            setFileUploadprogress(0);
            showToast(error.toString(), 'e');
          });
      } else showToast('Maximum file size for image is 10MB. Please select image below 10 MB', 'e');
    } else {
      showToast('We only support JPEG, and PNG file formats. The selected file are not in the supported format', 'e');
    }
  };
  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'orgName':
        setOrgName(value);
        break;
      case 'orgDescription':
        setOrgDescription(value);
        break;
      case 'aiKey':
        setHasAIKey(true);
        if (value?.includes('*')) {
          setAIKey("");
        } else {
          setAIKey(value);
        }
        break;
      default:
        break;
    }
  };
  const generateFormModelSchema = () => {
    let fields = [ValidationTool.GenerateSchema('orgName', 'Org Name', 'TEXT', true)];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };
  const defaultValues = {
    orgName: orgName || '',
    imageUrl: imageUrl || '',
    orgDescription: orgDescription || '',
  };
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  const handleDeleteOrgImage = () => {
    setOrgImage('');
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" height="100%">
            <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: 'center', flexGrow: 1 }}>
              <Box position="relative" display="inline-block">
                <RHFUploadAvatar
                  name="imageUrl"
                  accept="image/*"
                  maxSize={100000}
                  onDrop={handleDrop}
                  handleDeleteProfiles={handleDeleteOrgImage}
                  url={imageUrl}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of 5Mb
                    </Typography>
                  }
                />
              </Box>
              <Stack alignItems="center" justifyContent="center" spacing={2}>
                <LoadingButton
                  variant="contained"
                  loading={isLeaveOrgLoading}
                  onClick={() => {
                    handleOpenConfirm('remove');
                  }}
                >
                  Leave Organization
                </LoadingButton>
                {isUserHasPermission && userOrgDetails.is_portal_admin && (
                  <LoadingButton
                    variant="contained"
                    loading={isDeleteOrgLoading}
                    onClick={() => {
                      handleOpenConfirm('delete');
                    }}
                  >
                    Delete Organization
                  </LoadingButton>
                )}
              </Stack>
              <ConfirmationDialog
                open={confirmOpen}
                onClose={handleCloseConfirm}
                onConfirm={handleConfirmAction}
                title={`Confirm ${currentAction === 'delete' ? 'Deletion' : 'Leave'}`}
                message={`Are you sure you want to ${
                  currentAction === 'delete' ? 'delete this organization' : 'Leave this organization'
                }? This action cannot be undone`}
                submitButtonName="Yes"
              />
              {fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box display="flex" flexDirection="column" height="100%">
            <Card sx={{ p: 3, flexGrow: 1 }}>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 3,
                  columnGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField
                  name="orgName"
                  label="Org Name"
                  value={orgName}
                  onChange={handleInputChange}
                  error={formValidationErrors?.orgName}
                  helperText={formValidationErrors?.orgName}
                />
                <RHFTextField
                  name="aiKey"
                  label="AI Key"
                  value={aiKey}
                  onChange={handleInputChange}
                  helperText={
                    <a
                      href="https://www.youtube.com/watch?v=ZEQ102Wq1Lk"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'none',
                        marginLeft: '-10px',
                        cursor: 'pointer'
                      }}
                    >
                      Click here to learn how to get the AI Key?(Enabled Chaptgpt-4.0-latest)
                    </a>
                  }
                />
                <FormControl fullWidth>
                  <InputLabel id="org-admin-select-label">Select Admin</InputLabel>
                  <Select
                    labelId="org-admin-select-label"
                    value={selectedAdmin} // Bind to the selectedAdmin state
                    onChange={handleSelectChange}
                    fullWidth
                    label="Select Admin"
                    displayEmpty
                  >
                    {listOrgAdmins.map((admin) => (
                      <MenuItem key={admin.user_id} value={admin.user_id} disabled={!admin.is_enabled}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography>{`${admin.first_name} (${admin.email})`}</Typography>
                          {admin.is_portal_admin && <Chip label="Portal Admin" color="primary" size="small" />}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <RHFTextField
                  name="orgDescription"
                  multiline
                  rows={4}
                  label="Organization Description"
                  value={orgDescription}
                  onChange={handleInputChange}
                />
              </Box>
              {isUserHasPermission && userOrgDetails.is_portal_admin && (
                <Stack alignItems="flex-end">
                  <Stack spacing={3} direction="row" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained" loading={isSaveChangesLoading}>
                      Save Changes
                    </LoadingButton>
                  </Stack>
                </Stack>
              )}
            </Card>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
export default OrgInfoComponent;
