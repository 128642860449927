import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import TextIconLabel from 'src/components/TextIconLabel';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';

const AIContentComponent = ({ handleClose, handleUseAIContent }) => {
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultPostGenerationCount = 1;
  const defaultPostWords = 50;
  const defaultToneOfVoice = 'Polite';
  const toneOfVoiceList = [
    'Polite',
    'Witty',
    'Enthusiastic',
    'Friendly',
    'Informational',
    'Funny',
    'Formal',
    'Informal',
    'Humorous',
    'Serious',
    'Optimistic',
    'Motivating',
  ];

  //State Variable
  const [aiContentTitle, setAiContentTitle] = useState('');
  const [aiContentUrl, setAiContentUrl] = useState('');
  const [approximateWords, setApproximateWords] = useState(defaultPostWords);
  const [postToGenerate, setPostToGenerate] = useState(defaultPostGenerationCount);
  const [isAIContentLoading, setAIContentLoading] = useState(false);
  const [generatedAIContent, setGeneratedAIContent] = useState(null);
  const [selectedAIContent, setSelectedAIContent] = useState(0);
  const [toneOfVoice, setToneOfVoice] = useState('Polite');
  const [isGenerateHashtags, setGenerateHashTag] = useState(true);
  const [isIncludeEmoji, setIncludeEmoji] = useState(true);
  const [isLoaderActive, setLoaderActive] = useState(true);
  const [aiCreditLimit, setAiCreditLimit] = useState(0);
  const [isUsersOwnAIEnabled, setUsersOwnAI] = useState(false);

  useEffect(() => {
    checkAIEnabledForThisBrand();
  }, []);

  const checkAIEnabledForThisBrand = () => {
    let postProps = {
      url: integrateModuleURL.checkAIEnabledForThisBrand,
      body: null,
      successCallback: aiEnabledSuccesscallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const aiEnabledSuccesscallback = (data, message) => {
    if (data) {
      setLoaderActive(false);
      setUsersOwnAI(true);
      setAiCreditLimit(0);
    } else {
      setUsersOwnAI(false);
      checkAndGetAICredit();
      // showToast("Ensure your OpenAI API key is configured in the 'Manage Brand' or 'Manage Organization' section to start using AI-generated content.", 's');
      // navigate('/manage-brand');
    }
  };

  const checkAndGetAICredit = () => {
    let postProps = {
      url: integrateModuleURL.checkAndGetAICredit,
      body: null,
      successCallback: aiCreditSuccesscallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const aiCreditSuccesscallback = (data, message) => {
    setLoaderActive(false);
    if (data.available_ai_token > 0) {
      setAiCreditLimit(data.available_ai_token);
    } else {
      setAiCreditLimit(0);
    }
  };

  const failureCallback = (message) => {
    message && showToast(message, 'e');
    setLoaderActive(false);
    setAIContentLoading(false);
  };

  const handleInputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    switch (name.toLowerCase()) {
      case 'aicontenttitle':
        setAiContentTitle(value);
        setAiContentUrl('');
        break;
      case 'aicontenturl':
        setAiContentUrl(value);
        setAiContentTitle('');
        break;
      case 'approximatewords':
        setApproximateWords(value);
        break;
      case 'posttogenerate':
        setPostToGenerate(value);
        break;
      case 'isgeneratehashtags':
        setGenerateHashTag(!isGenerateHashtags);
        break;
      case 'isincludeemoji':
        setIncludeEmoji(!isIncludeEmoji);
        break;
      default:
        break;
    }
  };

  const generateAIContentSuccesscallback = (data, message) => {
    if (data.status) {
      setGeneratedAIContent(data.content);
    } else {
      data.message && showToast(data.message, 'e');
    }
    setAIContentLoading(false);
    if (!isUsersOwnAIEnabled) {
      checkAndGetAICredit();
    }
  };

  const handleGenerateAIContent = () => {
    setAIContentLoading(true);
    let aiContentString = aiContentTitle ? CommonHelper.CleanString(aiContentTitle) : aiContentTitle;
    if (aiContentUrl) {
      if (!CommonHelper.isValidUrl(aiContentUrl)) {
        showToast('Please enter a valid URL', 'e');
        return;
      }
    }
    let bodyprops = {
      prompt: aiContentString,
      content_url: aiContentUrl,
      approximate_words: approximateWords ? parseInt(approximateWords) : defaultPostWords,
      post_to_generate: postToGenerate ? parseInt(postToGenerate) : defaultPostGenerationCount,
      is_generate_hashtags: isGenerateHashtags,
      is_include_emoji: isIncludeEmoji,
      type: toneOfVoice ? toneOfVoice : defaultToneOfVoice,
    };
    let postProps = {
      url: integrateModuleURL.generateAIContent,
      body: bodyprops,
      successCallback: generateAIContentSuccesscallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const useAIContent = () => {
    handleUseAIContent(generatedAIContent[selectedAIContent]);
  };

  const handleSelectAIContent = (index) => {
    setSelectedAIContent(index);
  };

  const handleNavigateToBrand = () => {
    navigate('/manage-brand');
  };

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          minHeight: '300px',
          maxHeight: '70vh', // Decreased maxHeight to '70vh'
          height: '70vh', // Decreased height to '70vh'
        },
      }}
    >
      <DialogTitle>Generate AI Content (Working on credit Based)</DialogTitle>
      {isLoaderActive ? (
        <Spinner />
      ) : aiCreditLimit === 0 && !isUsersOwnAIEnabled ? (
        <>
          <DialogContent>
            <Box sx={{ textAlign: 'center', p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                You have reached your free credit limit.
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Please wait until next month to get free AI credit
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                OR
              </Typography>
              <Typography variant="body1">
                To use your own AI <strong onClick={handleNavigateToBrand} style={{ cursor: 'pointer', color: theme.palette.primary.main }}>click here</strong>
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <InputLabel>Available AI Credit: {aiCreditLimit}</InputLabel>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="aiContentTitle"
                  label="Enter your prompt here"
                  value={aiContentTitle}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  onChange={handleInputChange}
                  sx={{ mb: 2, mt: 1 }}
                />
                <Typography variant="body2" sx={{ textAlign: 'center', mb: 2 }}>
                  OR
                </Typography>
                <TextField
                  name="aiContentUrl"
                  label="Enter your website or blog URL"
                  value={aiContentUrl}
                  variant="outlined"
                  fullWidth
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                {!generatedAIContent?.length > 0 && (
                  <TextIconLabel
                    icon={<ErrorOutlineIcon sx={{ mr: 1, fontSize: 18 }} />}
                    value="Ask your content idea like, Eg. 'Write a prompt for a New Year message'"
                  />
                )}
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Tone of Voice
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {toneOfVoiceList.map((tone) => (
                    <Chip
                      key={tone}
                      label={tone}
                      onClick={() => setToneOfVoice(tone)}
                      sx={{
                        borderRadius: 8,
                        ...(toneOfVoice === tone && {
                          bgcolor: 'primary.main',
                          color: 'white',
                          fontWeight: 'bold',
                        }),
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          color: 'white',
                        },
                      }}
                    />
                  ))}
                </Box>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="approximateWords"
                      label="Approximate Words"
                      type="number"
                      value={approximateWords}
                      variant="outlined"
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="postToGenerate"
                      label="Post to Generate"
                      type="number"
                      value={postToGenerate}
                      variant="outlined"
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <StyledComponent.SwitchIcon
                          name="isGenerateHashtags"
                          sx={{ m: 1 }}
                          checked={isGenerateHashtags}
                          onChange={handleInputChange}
                        />
                      }
                      label="Generate Hashtags"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <StyledComponent.SwitchIcon
                          name="isIncludeEmoji"
                          sx={{ m: 1 }}
                          checked={isIncludeEmoji}
                          onChange={handleInputChange}
                        />
                      }
                      label="Include Emoji"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                {generatedAIContent?.length > 0 && (
                  <>
                    <Typography sx={{ fontWeight: 600, mt: 1, mb: 1 }}>Choose your content</Typography>
                    <Box
                      sx={{
                        maxHeight: '50vh', // Set maxHeight for the scrollable area
                        overflowY: 'auto', // Enable vertical scrolling
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                        gap: 2,
                      }}
                    >
                      {generatedAIContent.map(
                        (content, index) =>
                          content && (
                            <Box
                              key={index}
                              onClick={() => handleSelectAIContent(index)}
                              sx={{
                                border:
                                  index === selectedAIContent
                                    ? `1px solid ${theme.palette.primary.main}`
                                    : `1px solid ${theme.palette.text.disabled}`,
                                borderRadius: 2,
                                p: 2,
                                cursor: 'pointer',
                                backgroundColor: index === selectedAIContent ? `${theme.palette.secondary.main}` : 'white',
                                transition: '0.3s',
                                ':hover': {
                                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                },
                              }}
                            >
                              <Radio checked={index === selectedAIContent} sx={{ display: 'none' }} />
                              <Box sx={{ typography: 'body2', wordWrap: 'break-word' }}>{content}</Box>
                            </Box>
                          )
                      )}
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {aiCreditLimit > 0 && <InputLabel>Available AI Credit: {aiCreditLimit}</InputLabel>}
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              disabled={!aiContentTitle && !aiContentUrl}
              loading={isAIContentLoading}
              onClick={handleGenerateAIContent}
            >
              Generate {!isMobile && 'Content'}
            </LoadingButton>
            {generatedAIContent && (
              <Button variant="outlined" onClick={useAIContent}>
                Use {!isMobile && 'Content'}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AIContentComponent;
