import { Box, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL, userModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import FailedPostGrid from './failedPostGrid.component';
import FailedPostFilter from './failedPostFilter.component';
import NoUpcomingPost from '../../../assets/app-image/no-upcoming-posts.svg';
import { useParams } from 'react-router-dom';

const FailedPost = ({ publicUserName }) => {

    const { showToast } = useToaster();
    const [loader, setLoader] = useState(true);
    const [failedPostList, setFailedPostList] = useState([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("scheduled_time");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const { urlKey } = useParams();

    useEffect(() => {
        let currentDate = new Date();
        let fDate = new Date(currentDate);
        fDate.setFullYear(currentDate.getFullYear() - 1);
        fDate = fDate;
        let tDate = new Date(currentDate);
        tDate.setFullYear(currentDate.getFullYear());
        tDate = tDate;
        setFromDate(fDate);
        setToDate(tDate);
    }, []);

    useEffect(() => {
        if (fromDate && toDate) {
            getFailedPost();
        }
    }, [order, orderBy, fromDate, toDate])

    const postSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        let filteredData = data.filter((data) => data.connected_profile_details?.length > 0 )
        setFailedPostList([...filteredData]);
        setLoader(false);
    }

    const failureCallback = (message) => {
        message && showToast(message, "e");
        setLoader(false);
    }

    const getFailedPost = () => {
        let getProps = {
            url: `${userModuleURL.getFailedCalendarEventsPublic}${fromDate.toISOString()}&to=${toDate.toISOString()}&sort_by=${orderBy}&sort_order=${order}&url_key=${urlKey}`,
            successCallback: postSuccessCallback,
            failureCallback
        }
        HttpServices.Get(getProps);
    }

    const handleReCallFailedPost = (sortBy = "", sortOrder = "") => {
        setLoader(true);
        setOrder(sortOrder);
        setOrderBy(sortBy);
    }

    const handleApplyFilter = (fromDate, toDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
    }

    return (
        <Box>
            {loader ?
                <Card style={{ minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                    <Spinner />
                </Card>
                :
                <>
                    <Card sx={{ mb: 2, p: 2, overflowY: 'auto', visibility: true }} >
                        <FailedPostFilter handleApplyFilter={handleApplyFilter} fromDate={fromDate} toDate={toDate} />
                    </Card>
                    {failedPostList && failedPostList.length > 0 ?
                        <FailedPostGrid failedPostList={failedPostList} orderBy={orderBy} order={order} handleReCallFailedPost={handleReCallFailedPost} publicUserName={publicUserName} />
                        :
                        <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 750, minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                            <img src={NoUpcomingPost} style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }} alt="No Upcoming Post" />
                        </Card>
                    }
                </>
            }
        </Box>
    );
}
export default FailedPost;