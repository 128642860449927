import { Avatar, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { CalendarUtil } from '../calendarUtil';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { CommonHelper } from 'src/utils/commonHelper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import PostPreview from 'src/pages/scheduledPost/Preview/post-preview.component';

const CalendarEventsView = ({ event }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOpenDraftPost = (row, open = false) => {
    if (!isMobile) {
      let data = {
        content: postView,
        data: row,
      };
      showRightSidebar(data, '500px');
    }
  };

  const postView = (row) => {
    let data = {
      id: row,
    };
    let postDetails = {
      data: data,
    };
    return <PostPreview postDetails={postDetails}></PostPreview>;
  };

  const { showRightSidebar } = useRightSidebar();

  return (
    <>
      <Paper
        elevation={5}
        container
        sx={{
          padding: '10px',
          overflow: 'hidden',
          backgroundColor: CalendarUtil.GetEventColor(event.extendedProps.data),
        }}
        onClick={() => {
          handleOpenDraftPost(event.extendedProps.data.id, true);
        }}
      >
        <Stack direction="row" alignItems="flex-start" flexWrap="wrap" sx={{ gap: 0.5 }}>
          <Stack direction="row" alignItems="center">
            {PostDetailsHelper.getProfile(
              event.extendedProps.data.created_by.name,
              event.extendedProps.data.created_by.email,
              event.extendedProps.data.created_by.profile_pic
            )}
            <Stack direction="column" alignItems="flex-start" sx={{ width: '100%' }}>
              <Typography
                variant={'subtitle1'}
                sx={{
                  fontSize: '14px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minWidth: 130,
                  maxWidth: 130,
                }}
              >
                {event.extendedProps.data.created_by.name}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ fontSize: '12px' }}>
                {CommonHelper.formatDateAndTime(
                  CommonHelper.GetBrandTimeFromUTC(event.extendedProps.data.scheduled_time),
                  'time'
                )}
              </Typography>
            </Stack>
          </Stack>

          {/* <Divider orientation="vertical" flexItem /> */}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            minWidth="130px"
            maxWidth="130px"
          >
            {PostDetailsHelper.getSocialMediaViewWithTooltip(event.extendedProps.data.connected_profile_details)}
          </Stack>

          {/* <Divider orientation="vertical" flexItem /> */}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ width: isMobile ? '100%' : ' auto' }}
          >
            <Stack direction="row" flexWrap="wrap" spacing={1.5} sx={{ minWidth: 0, width: '100%' }}>
              <Stack sx={{ width: isMobile ? '100%' : '160px' }}>
                {PostDetailsHelper.getPostMedias(event.extendedProps.data.media_url+"_thumbnail.jpg", 8)}
              </Stack>
              {/* <Divider orientation="vertical" flexItem /> */}
              <Typography
                variant={'subtitle2'}
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  lineHeight: 1.2,
                  fontSize: '12px',
                  margin: isMobile ? '10px 0 !important' : '0 10px !important',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 3, // Limit to 3 lines
                  WebkitBoxOrient: 'vertical',
                  whiteSpace: 'normal', // Ensure text wraps
                  maxHeight: `${3 * 1.5}em`, // Adjust based on line height
                  maxWidth: 500, // Example: Set a maximum width to make lines shorter
                }}
              >
                {event.title}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};
export default CalendarEventsView;
