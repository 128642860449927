import { MoreHoriz as MoreHorizIcon, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { FavoriteBorderRounded as FavoriteBorderRoundedIcon, Public as PublicIcon, MoreVert as MoreVertIcon, FiberManualRecord as FiberManualRecordIcon, BookmarkBorder as BookmarkBorderIcon } from '@mui/icons-material';

import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import VerticalAlignBottomSharpIcon from '@mui/icons-material/VerticalAlignBottomSharp';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, IconButton, Typography, useTheme, Stack, useMediaQuery, Divider } from '@mui/material';
import Youtubeshare from '../../../assets/fbshare.svg'
import { CommonHelper } from 'src/utils/commonHelper';
import Youtubecomment from '../../../assets/comment.svg';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useState,} from 'react';

const YoutubePostCard = ({ media_url, description, like_count, view_count, comment_count, is_liked, profile, handleUnLikePost,
  handleLikePost,
  handleFocusComment }) => {
    const hasManageLikesCommentPermission = CommonHelper.CheckForPermissionAvailableForUser(CommonHelper.PermissionList.MANAGE_COMMENTS_LIKES);

    let mediaFiles = CommonHelper.PublishedPostProcessMediaUrl(media_url)?.media_data;
  const [showFullDescription, setShowFullDescription] = useState(false);
  
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
    const imageView = () => {
      let view = [];
      let xWidth = 6;
      const remainingImagesCount = mediaFiles.length > 5 ? mediaFiles.length - 4 : 0;
      switch (mediaFiles.length) {
        case 1:
          xWidth = 12;
          break;
        case 2:
          xWidth = 12;
          break;
        case 3:
        case 4:
          xWidth = 6;
          break;
        default:
          xWidth = 6;
          break;
      }
      view = mediaFiles.slice(0, 4).map((image, i) => (
        <Grid
          item
          xs={mediaFiles.length === 3 && i === 2 ? 12 : xWidth}
          key={i}
          sx={{
            maxWidth: 500,
            maxHeight: 250,
            overflow: 'hidden',
            position: 'relative',
            display: 'inline-flex',
            opacity: 1,
          }}
        >
          {image && image?.url ? (
            <>
              <img
                src={image.url}
                alt={image.url}
                width={'100%'}
                height={mediaFiles.length > 1 ? 150 : 200}
                style={{ objectFit: 'cover' }}
              />
            </>
          ) : (
            <img src={image} alt={image} width={'100%'} height={200} style={{ objectFit: 'cover' }} />
          )}
        </Grid>
      ));
      return (
        <Grid container sx={{ position: 'relative' }}>
          {view}
          {remainingImagesCount > 0 && (
            <Typography
              variant="h2"
              color="white"
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                zIndex: 1,
                marginRight: '20%',
                marginBottom: '10%',
              }}
            >
              +{remainingImagesCount}
            </Typography>
          )}
        </Grid>
      );
    };
  
    const theme = useTheme();
    const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
    const profileTime = () => {
      return (
        <>
          <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{gap: '0.3rem'}}>
            <Typography variant="span">{CommonHelper.DateTimeFromCurrentTime(profile?.date)}</Typography>
            <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
            <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
          </Stack>
        </>
      );
    };
  
  return (
    <Stack container justifyContent="center" alignItems="center">
      <Card
         sx={{
          maxWidth: 500,
          minWidth: respwidth ? 400 : null,
          boxShadow: 'none',
          border: 'solid 2px',
          borderColor: theme.palette.preview.postcard.color,
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
              alt={profile.profile_name ? profile.profile_name : profile.id}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: 40,
                width: 40,
                bgcolor: theme.palette.primary.main,
              }}
              style={{ color: 'white' }}
              action={<MoreVertIcon sx={{ mt: 1 }} />}
            />
          }

          title={profile?.profile_name || "User Profile"}
          sx={{
            fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 },mb:1.5
          }}
          subheader={profileTime()}
          action={<MoreVertIcon sx={{ mt: 1 }} />}
        />
        <CardMedia sx={{ pl: 2, pr: 2 }}>
          {imageView()}
        </CardMedia>
        <CardContent >
        <Typography
              noWrap={!showFullDescription}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              {description}
            </Typography>
          {!showFullDescription && description.length > 100 && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show More
              </Typography>
            )}
            {showFullDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show Less
              </Typography>
            )}
        </CardContent>
        <Divider/>
        <CardActions  >
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <IconButton aria-label="chatbubbleoutline"  onClick={hasManageLikesCommentPermission && !is_liked &&handleLikePost ? handleLikePost : null}>
                <Typography sx={{ visibility: like_count === 0 && 'hidden',mr:0.5 }}>{CommonHelper.formatNumber(like_count)}</Typography>
                {is_liked ? <ThumbUpAltIcon sx={{ fontSize: '20px',}} /> : <ThumbUpOffAltIcon sx={{ fontSize: '20px',}} />}
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="share"   onClick={hasManageLikesCommentPermission && is_liked && handleUnLikePost ? handleUnLikePost : null}>
                <ThumbDownOffAltIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="favorite" onClick={handleFocusComment ? handleFocusComment : null}>
                <Typography sx={{ visibility: comment_count === 0 && 'hidden',mr:0.5 }}>{CommonHelper.formatNumber(comment_count)}</Typography>
                <img
                  src={Youtubecomment}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px' }}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="share" >
                <Typography sx={{ visibility: view_count === 0 && 'hidden',mr:0.5 }}>{CommonHelper.formatNumber(view_count)}</Typography>
                <RemoveRedEyeIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Stack>

  )
}
export default YoutubePostCard;