import { Hidden, TableBody } from "@mui/material";
import { StyledComponent } from "src/components/styled-components/styledComponent";
import { CommonHelper } from "src/utils/commonHelper";
import { PostDetailsHelper } from "src/utils/postDetailsHelper";
import FailedPostNestedGridAction from "./FailedPostNestedGridAction.componenet";

const FailedPostNestedGridBody = ({ failedPostId, scheduledEventDetails }) => {
    return (
        <>
            <TableBody>
                {scheduledEventDetails.map((row, i) => {
                    return (
                        <>
                            <StyledComponent.StyledTableRow key={"historyRow.date"} sx={{
                                backgroundColor: row.failed ? 'rgba(255, 0, 0, 0.1)' : 'inherit'
                            }}>
                                <StyledComponent.StyledTableCell component="th" scope="row">
                                    {CommonHelper.formatDateAndTime(row.updated_at)}
                                </StyledComponent.StyledTableCell>
                                <StyledComponent.StyledTableCell>
                                    {PostDetailsHelper.GetPostContent(row.description)}
                                </StyledComponent.StyledTableCell>
                                <StyledComponent.StyledTableCell >
                                    {PostDetailsHelper.getPostMedias(row.media_url+"_thumbnail.jpg")}
                                </StyledComponent.StyledTableCell>
                                <StyledComponent.StyledTableCell>
                                    {row.failure_reason}
                                </StyledComponent.StyledTableCell>
                                <StyledComponent.StyledTableCell>
                                    {row.post_id}
                                </StyledComponent.StyledTableCell>
                                <Hidden only={['xs']}>
                                    <StyledComponent.StyledTableCell sx={{ width: '10%' }} align="left">
                                        {PostDetailsHelper.getSocialMediaViewWithTooltip(row.connected_profile_details)}
                                    </StyledComponent.StyledTableCell>
                                </Hidden>
                                <FailedPostNestedGridAction failedPostId={failedPostId} scheduledEventDetails={row}></FailedPostNestedGridAction>

                            </StyledComponent.StyledTableRow>
                        </>
                    )
                })}
            </TableBody>
        </>
    )
}

export default FailedPostNestedGridBody;