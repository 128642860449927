import { Box, IconButton, Tooltip } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import ScheduledPostActionPopup from "./allPostGridActionPopup.component";
import { ModeEdit, Send } from "@mui/icons-material";
import { postsModuleURL, userModuleURL, imageModeuleUrl } from "src/services/urlService";
import { useToaster } from "src/utils/toaster/toasterContext";
import HttpServices from "src/services/httpService";
import ConfirmationDialog from "src/components/ConfirmationDialog";
import { useNavigate } from 'react-router-dom';
import { CommonHelper } from "src/utils/commonHelper";

const ScheduledPostGridAction = ({ scheduledPost, handleReCallScheduledPost }) => {
    const { showToast } = useToaster();
    const [selectedPopOverPost, setPopOverPost] = useState(null);
    const [popoverElement, setPopoverElement] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmationDialogue, setConfirmationDialogue] = useState(null);
    const navigate = useNavigate();

    const handleOpenMenu = (event, scheduledPost) => {
        setPopOverPost(scheduledPost);
        setPopoverElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setPopOverPost(null);
        setPopoverElement(null);
    };

    const closeConfirmationDialogue = () => {
        setConfirmationDialogue(null)
    };

    const failureCallback = (message) => {
        message && showToast(message, 'e');
        handleCloseMenu();
        closeConfirmationDialogue()
        setLoading(false);
    };


    const postNowSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallScheduledPost();
    };


    const handlePostNow = () => {
        handleCloseMenu()
        setConfirmationDialogue({
            "isOpen": true,
            "title": "Confirm Post Now",
            "message": "Are you sure you want to Post this Schedule?",
            "submitFunction": confirmPostNow
        })
    };

    const confirmPostNow = (post) => {
        let postNowProps = {
            schedule_id: scheduledPost.id,
            new_post_time: new Date().toISOString(),
        };
        let postProps = {
            url: postsModuleURL.reScheduleNewPost,
            body: postNowProps,
            successCallback: postNowSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };
    const fetchMediaMetaData = (data, editAsNew = false) => {
        let mediaBody = {
            media_urls: data?.media_url.split(",")
        };
        let postProps = {
            url: imageModeuleUrl.fetchMediaMetadata,
            body: mediaBody,
            successCallback: (imageData, message) => { fetchMediaSuccessCallback(imageData, message, data, editAsNew) },
            failureCallback: failureCallback
        }
        HttpServices.Post(postProps);
    }
    const fetchMediaSuccessCallback = (imageData, mesaage, data, editAsNew) => {
        if (editAsNew) {
            uploadEditDataToNewPost(data, imageData ? imageData.media_datas : []);
        }
        else {
            uploadDataToPost(data, imageData ? imageData.media_datas : []);
        }

    }
    const newPostSucessCallback = (data) => {
        if (data.media_url && data.media_url.length > 0)
            fetchMediaMetaData(data);
        else
            uploadDataToPost(data);
    }

    const editNewPostSucessCallback = (data) => {
        let editAsNew = true
        if (data.media_url && data.media_url.length > 0)
            fetchMediaMetaData(data, editAsNew);
        else
            uploadEditDataToNewPost(data);
    }

    const uploadDataToPost = (data, imageData = []) => {
        setLoading(false);
        const updatedData = {
            ...data,
            media_data: imageData,
            schedule_id: scheduledPost?.id
        };
        const newPostData = CommonHelper.CommonNewPostAddMediaAllService(updatedData, imageData)
        navigate('/new-post', { state: { data: newPostData } });
    }
    const uploadEditDataToNewPost = (data, imageData = []) => {
        setLoading(false);
        const newPostData = CommonHelper.CommonNewPostAddMediaAllService(data, imageData)
        navigate('/new-post', { state: { data: newPostData } });
    }
    const handleEditPost = () => {
        setLoading(true);
        CommonHelper.CommonNewPostStruct(scheduledPost.id, newPostSucessCallback, failureCallback)
    };
    const handleEditAsNewPost = () => {
        setLoading(true);
        CommonHelper.CommonNewPostStruct(scheduledPost.id, editNewPostSucessCallback, failureCallback)
    };
    const handleSaveAsDraft = () => {
        let props = {
            schedule_post_id: scheduledPost.id,
        };
        let postProps = {
            url: postsModuleURL.savePostAsDraft,
            body: props,
            successCallback: saveAsDraftSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const saveAsDraftSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
    };

    const handleDeletePost = () => {
        handleCloseMenu()
        setConfirmationDialogue({
            "isOpen": true,
            "title": "Confirm Deletion",
            "message": "Are you sure you want to delete this Schedule?",
            "submitFunction": confirmDeletePost
        })
    };

    const confirmDeletePost = () => {
        let postProps = {
            url: postsModuleURL.deleteSchedule + scheduledPost.id,
            successCallback: deleteSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
        closeConfirmationDialogue()
    };


    const deleteSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallScheduledPost();
    };


    const handleUnapprovePost = () => {
        let unapproveProps = {
            schedule_id: scheduledPost.id,
        };
        let postProps = {
            url: userModuleURL.unApprovePost,
            body: unapproveProps,
            successCallback: unapproveSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const unapproveSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallScheduledPost();
    };



    const postActionProps = {
        popoverElement: popoverElement,
        handleCloseMenu,
        handleSaveAsDraft,
        handleEditAsNewPost,
        handleDeletePost,
        handleUnapprovePost,
        loading,
        hasEditAccess: scheduledPost.has_edit_access,
        hasApproveAccess: scheduledPost.has_approve_access,
        selectedPopOverPost: selectedPopOverPost,
    };

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="center">
                <IconButton
                    size="large"
                    color="inherit"
                    onClick={(e) => {
                        handleOpenMenu(e, scheduledPost);
                    }}
                    sx={{ padding: '6px', marginRight: '2px' }}
                >
                    <MoreVertIcon />
                </IconButton>
            </Box>
            <ScheduledPostActionPopup {...postActionProps} />
            {confirmationDialogue && <ConfirmationDialog
                open={confirmationDialogue.isOpen}
                onClose={() => closeConfirmationDialogue()}
                onConfirm={confirmationDialogue.submitFunction}
                title={confirmationDialogue.title}
                message={confirmationDialogue.message}
            />}
        </>
    )

}

export default ScheduledPostGridAction;