import { useState } from "react";
import { useEffect,useRef } from "react";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import { Config } from "src/config";
import { useToaster } from "src/utils/toaster/toasterContext";
import LinkedInProfileAuthorizationModal from "./linkedinProfileAuthorizationModal";
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';
// var openedWindow;
const LinkedInProfileIntegration = (props) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const { showToast } = useToaster();
    const isCustomDomain = CommonHelper.CustomDomain();
    let isProcessing = false;
    let intervalId = null;
    const messageListenerRef = useRef(null);

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthCodeReceivedFromLinkedIn();
        }, Config.Common.socialAccountsIntegrationInterval);
        return (() => {
            clearInterval(intervalId);
            cleanupListener();
        })
    }, [])

    const getLinkedProfileOauthToken = () => {
        window.open(
            `${Config.LinkedIn.authorizeUrl}?response_type=code&client_id=${Config.LinkedIn.clientId}&scope=${Config.LinkedIn.scope}&state=${Config.LinkedIn.state}&redirect_uri=${Config.LinkedIn.redirectUri}`, "_blank"
        );
    };
    const cleanupListener = () => {
        if (messageListenerRef.current) {
            window.removeEventListener("message", messageListenerRef.current, false);
            messageListenerRef.current = null; // Reset the ref
        }
    };
    const checkAuthCodeReceivedFromLinkedIn = () => {
        if (isCustomDomain) {
            const handleMessage = (event) => {
                const { type, code } = event.data;
                if (type === "linkedin_code" && code && !isProcessing) {
                    isProcessing=true
                    cleanupListener();
                    clearInterval(intervalId); // Stop polling
                    sendAccessTokenToServer(code); // Process the code
                }
            };
    
            if (!messageListenerRef.current) {
                window.addEventListener("message", handleMessage, false);
                messageListenerRef.current = handleMessage; // Save the reference
            }
        } else {
        var code = localStorage.getItem("linkedin_code");
        var error = localStorage.getItem("linkedin_error");
        if (code) {
            localStorage.removeItem("linkedin_code");
            clearInterval(intervalId);
            sendAccessTokenToServer(code);
        }
        else if (error) {
            localStorage.removeItem("linkedin_error");
            clearInterval(intervalId);
            showToast(error, 'e');
            handleClose();
        }
    }
    };
    const linkedInAccessTokenSuccessCallback = (data, message) => {
        showToast(message, 's');
        handleClose();
    }
    const linkedInAccessTokenFailureCallback = (message) => {
        showToast(message, 's');
        handleClose();
    }
    const sendAccessTokenToServer = (code) => {
        let linkedInForm = {
            code
        };
        let postProps = {
            url: integrateModuleURL.sendLinkedInAccessToken,
            body: linkedInForm,
            successCallback: linkedInAccessTokenSuccessCallback,
            failureCallback: linkedInAccessTokenFailureCallback
        }
        HttpServices.Post(postProps);
    };

    const handleClose = () => {
        props.handleClose();
        cleanupListener();
    }

    return (
        <>
        {isAuthorizationModalOpen &&
        <LinkedInProfileAuthorizationModal
            handleConnectLinkedInProfile={getLinkedProfileOauthToken}
            handleClose={handleClose}
        />}
         {isCustomDomain && <CustomDomainRedirectIframe serviceName="linkedin"/> }
        </>
        
    )
};
export default LinkedInProfileIntegration;
