import { useState } from "react";
import { useEffect } from "react";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import { Config } from "src/config";
import { useToaster } from "src/utils/toaster/toasterContext";
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';
import LinkedInProfileAuthorizationModal from "../linkedinIntegration/linkedinProfileAuthorizationModal";
// var openedWindow;
const LinkedInProfileReAuthenticate = ({ AccountId, handleClose, getConnectedNetworks }) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const { showToast } = useToaster();
    let isProcessing = false;
    const isCustomDomain = CommonHelper.CustomDomain();
    let intervalId = null;

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthCodeReceivedFromLinkedIn();
        }, Config.Common.socialAccountsIntegrationInterval);
        return (() => {
            clearInterval(intervalId);
        })
    }, [])

    const getLinkedProfileOauthToken = () => {
        window.open(
            `${Config.LinkedIn.authorizeUrl}?response_type=code&client_id=${Config.LinkedIn.clientId}&scope=${Config.LinkedIn.scope}&state=${Config.LinkedIn.state}&redirect_uri=${Config.LinkedIn.redirectUri}`, "_blank"
        );
    };

    const checkAuthCodeReceivedFromLinkedIn = () => {
        if (isCustomDomain) {
            const handleMessage = (event) => {
                const { type, code } = event.data;
                if (type === "linkedin_code" && code && !isProcessing) {
                    isProcessing = true
                    window.removeEventListener("message", handleMessage, false); // Remove event listener
                    clearInterval(intervalId); // Stop polling
                    sendAccessTokenToServer(code); // Process the code
                }
            };

            window.addEventListener("message", handleMessage, false);
        } else {
            var code = localStorage.getItem("linkedin_code");
            var error = localStorage.getItem("linkedin_error");
            if (code) {
                localStorage.removeItem("linkedin_code");
                clearInterval(intervalId);
                sendAccessTokenToServer(code);
            }
            else if (error) {
                localStorage.removeItem("linkedin_error");
                clearInterval(intervalId);
                showToast(error, 'e');
                handleClose();
            }
        }
    };
    const linkedInAccessTokenSuccessCallback = (data, message) => {
        showToast(message, 's');
        getConnectedNetworks();
        handleClose();
    }
    const linkedInAccessTokenFailureCallback = (message) => {
        showToast(message, 'e');
        handleClose();
    }
    const sendAccessTokenToServer = (code) => {
        let linkedInForm = {
            code,
            account_id: AccountId
        };
        let postProps = {
            url: integrateModuleURL.reAuthenticateLinkedInProfile,
            body: linkedInForm,
            successCallback: linkedInAccessTokenSuccessCallback,
            failureCallback: linkedInAccessTokenFailureCallback
        }
        HttpServices.Post(postProps);
    };



    return (
        <>
            {isAuthorizationModalOpen &&
                <LinkedInProfileAuthorizationModal
                    handleConnectLinkedInProfile={getLinkedProfileOauthToken}
                    handleClose={handleClose}
                />}
            {isCustomDomain && <CustomDomainRedirectIframe serviceName="linkedin" />}
        </>
    )
};
export default LinkedInProfileReAuthenticate;
