import { useEffect, useRef } from 'react';
const TwitterRedirect = () => {
    const intervalRef = useRef(null);
    const popupWindowURL = new URL(window.location.href);
    const token = popupWindowURL.searchParams.get("oauth_token");
    const tokenSecret = popupWindowURL.searchParams.get("oauth_verifier");
    const error = popupWindowURL.searchParams.get("error");
    const error_description = popupWindowURL.searchParams.get("error_description");
    useEffect(() => {
        if (token||tokenSecret || error) {
            // Store code or error in localStorage
            if (token && tokenSecret) {
                localStorage.setItem("twitter_token", token);
                localStorage.setItem("twitter_token_secret", tokenSecret);
            } else {
                localStorage.setItem("twitter_error", error_description || error);
            }

            // Notify parent window and close
            notifyParent(token,tokenSecret);
            window.close();
            return; // Prevent interval setup if code/error is already handled
        }

        const checkAuthCode = () => {
            const storedCode = localStorage.getItem("twitter_token");
            const storedSecretCode = localStorage.getItem("twitter_token_secret");
            if (storedCode) {
                notifyParent(storedCode,storedSecretCode);
                clearInterval(intervalRef.current);
            }
        };
        intervalRef.current = setInterval(checkAuthCode, 5000);
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [token, error, error_description]);

    const notifyParent = (authCode,authSecretCode) => {
        const message = { type: "twitter_token", twitter_token: authCode,twitter_token_secret: authSecretCode };

        try {
            const iframe = document.getElementById("iframe-id");
            if (iframe?.contentWindow) {
                iframe.contentWindow.postMessage(message, "*");
            } else if (window.opener) {
                window.opener.postMessage(message, "*"); 
            }
        } catch (e) {
            console.error("Failed to send message to parent window:", e);
        }
    };

    return null;
}
export default TwitterRedirect;