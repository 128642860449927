
import { Box, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import Spinner from 'src/utils/Loader/spinner';
import DraftsPostGrid from './Grid/PostGrid.component';


const Draft = () => {

  const [draftList, setDraftList] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const { showToast } = useToaster();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getDraftList('created_time', 'asc');
  }, [])

  const draftSuccessCallback = (data, message) => {
    setLoader(false);
    let filteredData = data.filter((data) => data.connected_profile_details?.length > 0 )
    setDraftList(filteredData);
  }
  const draftFailureCallback = (message) => {
    setLoader(false);
    message && showToast(message, 'e');
  }

  const getDraftList = (sortBy = "", sortOrder = "") => {
    setOrder(sortOrder)
    setOrderBy(sortBy)
    let currentDate = new Date();
    let tDate = currentDate.setDate(currentDate.getDate() + 365);
    currentDate.setHours(0, 0, 0, 0);
    tDate = new Date(currentDate).toISOString();
    let getProps = {
      url: `${postsModuleURL.loadDraft}?sort_by=${sortBy}&sort_order=${sortOrder}`,
      successCallback: draftSuccessCallback,
      failureCallback: draftFailureCallback
    }
    HttpServices.Get(getProps);
  }
  const handleReCallDraft = (sortBy = "", sortOrder = "") => {
    setLoader(true);
    getDraftList(sortBy, sortOrder)
  }

  return (
    <>
      <Helmet>
        <title>Drafts</title>
      </Helmet>
      <Box>
        {loader ?
          <Spinner />
          :
          <Card>
            <DraftsPostGrid draftList={draftList} orderBy={orderBy} order={order} handleReCallDraft={handleReCallDraft} />
          </Card>
        }
      </Box>
    </>
  );
};

export default Draft;
