import { Box, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import ScheduledReportActionPopup from "./PostGridActionPopup.component";
import { useNavigate } from 'react-router-dom';
import { reportModuleURL,imageModeuleUrl } from "src/services/urlService";
import { useToaster } from "src/utils/toaster/toasterContext";
import HttpServices from "src/services/httpService";
import ConfirmationDialog from "src/components/ConfirmationDialog";
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import ScheduledReportEditView from "../scheduledEditView.component";
import { CommonHelper } from "src/utils/commonHelper";

const ScheduledReportGridAction = ({ scheduledList, handleReCallScheduleReport }) => {
    const navigate = useNavigate();
    const { showToast } = useToaster();
    const [selectedPopOverPost, setPopOverPost] = useState(null);
    const [popoverElement, setPopoverElement] = useState(null);
    const [loading, setLoading] = useState(false);
    const { showRightSidebar } = useRightSidebar();
    const [confirmationDialogue, setConfirmationDialogue] = useState(null);

    const handleOpenMenu = (event, scheduledList) => {
        setPopOverPost(scheduledList);
        setPopoverElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setPopOverPost(null);
        setPopoverElement(null);
    };

    const closeConfirmationDialogue = () => {
        setConfirmationDialogue(null);
    };

    const failureCallback = (message) => {
        message && showToast(message, 'e');
        handleCloseMenu();
        setLoading(false);
        closeConfirmationDialogue();
    };
    const handleEditScheduledReport = () => {
        let data = {
            content:sideBarView,
        };
        showRightSidebar(data);
        handleCloseMenu();
    };
    const sideBarView = () => {
        
        return (<ScheduledReportEditView handleReCallScheduleReport={handleReCallScheduleReport} currReportData={scheduledList} />);
    };
    
   
      
    const handleDeleteReport = () => {
        handleCloseMenu();
        setConfirmationDialogue({
            isOpen: true,
            title: "Confirm Deletion",
            message: "Are you sure you want to delete this Draft?",
            submitFunction: confirmDeletePost
        });
    };

    const confirmDeletePost = () => {
        let postProps = {
            url: reportModuleURL.deleteScheduledReport,
            body : {
                report_id : scheduledList.id
            },
            successCallback: deleteSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
        closeConfirmationDialogue();
    };

    const deleteSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue();
        handleReCallScheduleReport();
    };

    const postActionProps = {
        loading,
        popoverElement: popoverElement,
        handleCloseMenu,
        handleEditScheduledReport,
        handleDeleteReport,
        selectedPopOverPost: selectedPopOverPost,
    };

    return (
        <>
            <Box display="flex" alignItems="right">
                    <IconButton
                        size="large"
                        color="inherit"
                        onClick={(e) => {
                            handleOpenMenu(e, scheduledList);
                        }}
                        sx={{ padding: '6px', marginRight: '2px', }}
                    >
                        <MoreVertIcon />
                    </IconButton>
            <ScheduledReportActionPopup {...postActionProps} />
            </Box>
            {confirmationDialogue && <ConfirmationDialog
                open={confirmationDialogue.isOpen}
                onClose={() => closeConfirmationDialogue()}
                onConfirm={confirmationDialogue.submitFunction}
                title={confirmationDialogue.title}
                message={confirmationDialogue.message}
            />}
        </>
    );
}

export default ScheduledReportGridAction;
