import React, { useState, useEffect } from 'react';
import { ImageList, ImageListItem, Checkbox, IconButton, Typography, Box, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Upload as UploadIcon, CheckCircleOutline as CheckCircleOutlineIcon, PlayCircleOutline as PlayIcon } from '@mui/icons-material';
import DeleteIcon from '../../assets/deletecomment.svg';
import HttpServices from 'src/services/httpService';

const MediaFileComponent = ({
  mediaData,
  handleDelete,
  handleUpload,
  handleSelectFile,
  selectedFiles,
  isFromNewPost = false,
  loadMoreMedia,
  showLoadMoreMedia,
}) => {

  const width = isFromNewPost ? 150 : 200;
  const height = isFromNewPost ? 150 : 200;

  // State Variable
  const [isLoading, setLoading] = useState(false);
  const [playingMedia, setPlayingMedia] = useState(null);
  const [cols, setCols] = useState(4);
  const [visibleMediaCount, setVisibleMediaCount] = useState(5);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [mediaToDelete, setMediaToDelete] = useState(null);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const allowedFileTypes = ['jpg', 'jpeg', 'png', 'mp4'];

  const filterMediaFiles = (media) => {
    const fileExtension = media.file_name.split('.').pop().toLowerCase();
    return allowedFileTypes.includes(fileExtension);
  };

  const handleUploadToServer = () => {
    setLoading(true);
    handleUpload();
    setLoading(false);
  };

  const isSelected = (media) => selectedFiles.some((file) => file.media_url === media.media_url);

  const handleMediaClick = (e, media) => {
    e.stopPropagation();
    handleSelectFile(e, media);
  };

  const handlePlay = async (media) => {
    if (media.file_type === 'VIDEO') {
      if (!media["media_blob"]) {
        const media_url = await HttpServices.GetImageFromAPI(media.media_url);
        media["media_blob"] = media_url;
      }
      setPlayingMedia(media);
      setMediaDialogOpen(true);
    }
  };

  const handleCloseMediaDialog = () => {
    setMediaDialogOpen(false);
    setTimeout(() => {
      setPlayingMedia(null);
    }, 300); // Delay to ensure dialog close animation completes
  };

  const handleImageClick = async (media) => {
    const image_url = await HttpServices.GetImageFromAPI(media.media_url);
    setSelectedImage({ ...media, media_url: image_url });
    setImageDialogOpen(true);
  };

  const handleCloseImageDialog = () => {
    setImageDialogOpen(false);
    setSelectedImage(null);
  };

  const handleDeleteClick = (event, media) => {
    event.stopPropagation(); // Add this line to stop event propagation
    setMediaToDelete(media);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    handleDelete(mediaToDelete);
    setDeleteConfirmOpen(false);
    setMediaToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setMediaToDelete(null);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const newCols = Math.floor(width / (isFromNewPost ? 320 : 280));
      setCols(newCols);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isFromNewPost]);

  return (
    <Box sx={{ mt: '1.25rem' }}>
      <ImageList cols={cols} gap={20} sx={{ justifyContent: 'flex-start', display: 'flex', flexWrap: 'wrap' }}>
        {mediaData?.length > 0 &&
          mediaData.filter(filterMediaFiles).map((media , index) => {
            const selected = isSelected(media);
            let isPlayingMedia = playingMedia && media.media_url === playingMedia.media_url;
            return (
              <ImageListItem
                key={media.media_url}
                sx={{
                  position: 'relative',
                  width: width,
                  height: height,
                  overflow: 'hidden',
                  borderRadius: 2,
                  boxShadow: 1,
                  cursor: 'pointer',
                  flex: '0 0 auto',
                }}
                onClick={(e) => { selected ? handleMediaClick(e, media) : (media.file_type === 'VIDEO' ? handlePlay(media) : handleImageClick(media)) }}
              >
                {media.file_type === 'IMAGE' ? (
                  <img
                    src={media.thumbnail_url}
                    alt={media.file_name.slice(16)}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      filter: selected ? 'brightness(0.9)' : 'none',
                      cursor: 'pointer',
                    }}
                  />
                ) : (
                  <img
                    src={media.thumbnail_url}
                    alt={media.file_name.slice(16)}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      filter: selected ? 'brightness(0.7)' : 'none',
                      cursor: 'pointer'
                    }}
                  />
                )}
                <Typography
                  variant="body2"
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    bgcolor: 'background.primary',
                    color: 'white',
                    textAlign: 'center',
                    p: 0.5,
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    cursor: 'pointer'
                  }}
                >
                  {index}
                </Typography>
                {selected ? (
                  <CheckCircleOutlineIcon
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: isFromNewPost ? '3rem' : '5rem',
                      color: 'primary.main',
                    }}
                  />
                )
                  :
                  (
                    media.file_type === 'VIDEO' && !isPlayingMedia &&
                    <PlayIcon
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: isFromNewPost ? '2.5rem' : '4rem',
                        color: 'rgba(255, 255, 255, 0.8)',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '50%',
                        padding: '0.5rem'
                      }}
                    />
                  )
                }
                <Box
                  className="overlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    transition: 'opacity 0.3s',
                  }}
                >
                  <Checkbox
                    checked={selected}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: isFromNewPost ? 20 : 30,
                        backgroundColor: 'white',
                        borderRadius: '20%',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        },
                      },
                    }}
                    onChange={(e) => handleSelectFile(e, media)}
                    onClick={(e) => e.stopPropagation()}
                  />
                  {!isFromNewPost && typeof handleDelete === 'function' && (
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={(event) => handleDeleteClick(event, media)} // Modify this line to pass the event
                        sx={{
                          color: 'white',
                          display: selected ? 'none' : 'block',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Added background color
                          borderRadius: '50%', // Optional: to make it circular
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          style={{ width: isFromNewPost ? 20 : 30, maxWidth: '22px', maxHeight: '22px' }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </ImageListItem>
            );
          })}
      </ImageList>
        {showLoadMoreMedia && (<Box textAlign="center" mt={2}>
          <LoadingButton
            onClick={loadMoreMedia}
            loading={isLoading}
            variant="contained"
            color="primary"
          >
            Load More
          </LoadingButton>
        </Box>)}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this media file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={mediaDialogOpen}
        onClose={handleCloseMediaDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Playing Media</DialogTitle>
        <DialogContent>
          {playingMedia && playingMedia.file_type === 'VIDEO' && (
            <video
              src={playingMedia?.media_blob}
              style={{
                width: '100%',
                height: 'auto', // Maintain aspect ratio
                maxHeight: '500px', // Fixed maximum height
                objectFit: 'contain', // Ensure the video fits within the container
              }}
              controls
              autoPlay
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMediaDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={imageDialogOpen}
        onClose={handleCloseImageDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Viewing Image</DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage.media_url}
              alt={selectedImage.file_name}
              style={{
                width: '100%',
                height: 'auto', // Maintain aspect ratio
                maxHeight: '500px', // Fixed maximum height
                objectFit: 'contain', // Ensure the image fits within the container
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default MediaFileComponent;
